import { ReactComponent as Folder } from 'assets/feathers/folder.svg'
import { styled, apply } from 'lib/styled'

const Cell = styled.div(apply('flex items-center'))

const FolderCell = ({ value }) => (
  <Cell>
    <Folder height={20} width={20} style={apply('mr-2')} />
    {value}
  </Cell>
)

export { FolderCell }
