import { reduceBy, prop, pipe, mergeRight } from '@solta/ramda-extra'
import { connect } from '@solta/stateless'
import { saveAs } from 'file-saver'
import { useFormikContext as useFormContext } from 'formik'
import { useLayoutEffect } from 'react'
import ReactTooltip from 'react-tooltip'

import { ComplianceDocumentsPanel } from './ComplianceDocumentsPanel'

import { ReactComponent as ExclamationCircleIcon } from 'assets/exclamation-circle.svg'
import { DeleteFileModal } from 'components/common/DeleteFileModal'
import { DOCUMENT_TYPES } from 'constants/documents'
import { JOB_STATUSES } from 'constants/jobs'
import { apply, styled } from 'lib/styled'
import { documentModule, jobDocumentList$, isOperatingFile$ } from 'modules/document'
import { inspectedJob$ } from 'modules/job'
import { useFileModal } from 'utils/hooks'

const Header = styled.div(apply('flex flex-row py-6 items-center'))
const Title = styled.h2(apply('m-0 text-xl font-light mr-1'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const Connected = connect(() => ({
  isOperatingFile: isOperatingFile$,
  jobDocumentList: jobDocumentList$,
  inspectedJob: inspectedJob$,
}))(ComplianceSection)

const { uploadJobDocument, downloadJobDocument, deleteJobDocument } = documentModule

function ComplianceSection({
  isOperatingFile,
  jobDocumentList,
  inspectedJob: { status },
}) {
  const [deleteFileModal, setDeleteFileModal] = useFileModal()
  const { setFieldValue, setFieldTouched, setFieldError } = useFormContext()

  // we hydrate/update the compliance documents here instead of other parent components because we don't want the entire form to subscribe to the document list, which will make the form re-render when a document is uploaded/deleted, thereby resetting other form fields while creating/editing a job
  useLayoutEffect(() => {
    const complianceDocuments = pipe(
      reduceBy((acc, doc) => acc.concat(doc), [], prop('documentType')),
      mergeRight({
        [DOCUMENT_TYPES.JOB_JSEA]: [],
        [DOCUMENT_TYPES.JOB_TERMS]: [],
        [DOCUMENT_TYPES.JOB_CASA]: [],
      })
    )(jobDocumentList)

    setFieldValue('jobInfo.complianceDocuments', complianceDocuments, false)
    setFieldTouched('jobInfo.complianceDocuments', false, false)
  }, [setFieldValue, jobDocumentList])

  const onUpload =
    (documentType) =>
    async ([file]) => {
      await uploadJobDocument(null, {
        file,
        documentType,
      })
    }

  const onDelete = ({ id: selectedDocId, fileName }) => {
    setDeleteFileModal({ isOpened: true, fileName, fileId: selectedDocId })
  }
  const onDownload = async ({ id: selectedDocId, fileName }) => {
    saveAs(await downloadJobDocument(selectedDocId), fileName)
  }
  const onRejected = (fieldName, errorMsg) => setFieldError(fieldName, errorMsg)

  const isDraft = status === JOB_STATUSES.DRAFT

  return (
    <>
      <Header>
        <Title>Compliance Documents</Title>
        <span>
          <ExclamationCircleIcon
            {...apply('h-1 w-1', {
              stroke: '#334155',
            })}
            data-tip="Compliance documents are only editable before a job is published. <br /> Please create a new job if you wish to do so."
            data-type="info"
          />
        </span>
      </Header>
      <Row style={apply('justify-between')}>
        <ComplianceDocumentsPanel
          containerStyle={apply('w-full mr-10')}
          fileType="Job JSEA"
          name={`jobInfo.complianceDocuments.${DOCUMENT_TYPES.JOB_JSEA}`}
          onUpload={onUpload(DOCUMENT_TYPES.JOB_JSEA)}
          onDelete={onDelete}
          onDownload={onDownload}
          onRejected={onRejected}
          isLoading={isOperatingFile}
          testId="job-jsea-panel"
          limit={1}
          canDelete={isDraft}
          canUpload={isDraft}
        />
        <ComplianceDocumentsPanel
          containerStyle={apply('w-full mr-10')}
          fileType="Job Terms"
          name={`jobInfo.complianceDocuments.${DOCUMENT_TYPES.JOB_TERMS}`}
          onUpload={onUpload(DOCUMENT_TYPES.JOB_TERMS)}
          onDelete={onDelete}
          onDownload={onDownload}
          onRejected={onRejected}
          isLoading={isOperatingFile}
          testId="job-terms-panel"
          limit={1}
          canDelete={isDraft}
          canUpload={isDraft}
        />
        <ComplianceDocumentsPanel
          containerStyle={apply('w-full')}
          fileType="Job CASA"
          name={`jobInfo.complianceDocuments.${DOCUMENT_TYPES.JOB_CASA}`}
          onUpload={onUpload(DOCUMENT_TYPES.JOB_CASA)}
          onDelete={onDelete}
          onDownload={onDownload}
          onRejected={onRejected}
          isLoading={isOperatingFile}
          testId="job-casa-panel"
          limit={1}
          canDelete={isDraft}
          canUpload={isDraft}
        />
      </Row>
      <DeleteFileModal
        isOpen={deleteFileModal.isOpened}
        setIsOpen={(open) => setDeleteFileModal({ isOpened: open })}
        fileName={deleteFileModal.fileName}
        deleteHandler={() => deleteJobDocument(deleteFileModal.fileId)}
      />
      <ReactTooltip html />
    </>
  )
}

export { Connected as ComplianceSection }
