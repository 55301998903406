import { isNilOrEmpty } from '@solta/ramda-extra'
import { useDropzone, ErrorCode } from 'react-dropzone'

const S3_UPLOAD_FILE_SIZE_LIMIT_IN_BYTES = 5_000_000_000

const defaultConfig = {
  // https://docs.aws.amazon.com/AmazonS3/latest/userguide/upload-objects.html
  // https://github.com/react-dropzone/react-dropzone/blob/master/src/index.js (need to be in bytes)
  maxSize: S3_UPLOAD_FILE_SIZE_LIMIT_IN_BYTES,
}

const createErrorMessage = (fileRejections = []) => {
  if (isNilOrEmpty(fileRejections)) {
    return 'Something went wrong. Please upload again'
  }

  // Only show error of 1st rejected file to keep things simple
  return {
    [ErrorCode.FileInvalidType]: 'Unsupported file type',
    [ErrorCode.FileTooLarge]: 'File too large. The maximum file size is 5GB',
    [ErrorCode.TooManyFiles]: 'Please select 1 file at a time',
  }[fileRejections[0].errors[0].code]
}

function useFileUpload(config) {
  const fileUpload = useDropzone({ ...defaultConfig, ...config })

  return fileUpload
}

export { useFileUpload, createErrorMessage }
