import { isNotNil, prop, defaultTo } from '@solta/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi } from 'lib/http-client'

const staffEntity = new schema.Entity('staff')

const StaffService = {
  async list({ query = '', role, limit = 1000, next }) {
    const config = {
      searchParams: {
        q: query,
        limit,
      },
    }
    if (isNotNil(next)) {
      config.searchParams.next = next
    }
    if (isNotNil(role)) {
      config.searchParams.role = role
    }

    const { items, paging } = await adminApi.get('staff', config).json()

    const normalized = normalize(items, [staffEntity])
    const entities = defaultTo({}, prop('staff', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async create(body) {
    const config = {
      json: body,
    }

    return adminApi.post('staff', config).json()
  },

  async read(id) {
    return adminApi.get(`staff/${id}`).json()
  },

  async update(id, payload) {
    const config = {
      json: payload,
    }

    return adminApi.patch(`staff/${id}`, config).json()
  },

  async reinvite(id) {
    return adminApi.post(`staff/${id}/reinvite`)
  },
}

export { StaffService }
