import { useState, useLayoutEffect } from 'react'
import Modal from 'react-modal'

import { Button as ButtonBase } from 'components/common/Button'
import { styled, apply } from 'lib/styled'
import { documentModule } from 'modules/document'

const Root = styled.div(apply('text-black bg-white rounded-lg p-8 py-4'))

const Title = styled.h2(apply('text-xl font-semibold mt-2 mb-4 px-2'))

const Message = styled.div(apply('text-base py-1 px-2'))

const Input = styled.input(
  apply('border-0 border-solid border-grey border-1 ml-2 px-2')
)

const ButtonContainer = styled.div(apply('w-full flex justify-between mt-6'))

const CancelButton = styled(ButtonBase)(apply('w-5 mx-1 bg-grey-lighter'))

const SaveButton = styled(ButtonBase)(
  apply('mr-1 bg-red-lightest border-red-lighter'),
  {
    '&:active': apply('bg-red-lighter border-red-light'),
  }
)

Modal.setAppElement('#root')

function FileRenameModal({ isOpen, setIsOpen, fileName, fileId }) {
  const [newFileName, setNewFileName] = useState(fileName)

  useLayoutEffect(() => {
    setNewFileName(fileName)
  }, [fileName])

  const closeModal = () => {
    setIsOpen(false)
  }

  const onSave = (newFileName) => {
    documentModule.renameJobDocument(fileId, newFileName)
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <Title>Rename file {fileName}</Title>
        <Message>New file name</Message>
        <Input value={newFileName} onChange={(e) => setNewFileName(e.target.value)} />

        <ButtonContainer>
          <CancelButton onClick={closeModal} variant="secondary">
            Cancel
          </CancelButton>
          <SaveButton onClick={() => onSave(newFileName)}>Save</SaveButton>
        </ButtonContainer>
      </Root>
    </Modal>
  )
}

export { FileRenameModal }
