import { subDays } from 'date-fns'
import { useHistory } from 'react-router-dom'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { Button } from 'components/common/Button'
import { JOB_STATUSES } from 'constants/jobs'
import { CanCreateJob } from 'lib/permissions'
import { styled, apply } from 'lib/styled'
import { jobModule } from 'modules/job'

const Container = styled.div(
  apply('flex justify-between items-center bg-blue-lightest')
)
const TabsContainer = styled.div(apply('flex'))
const Tab = styled.div(
  apply('flex justify-center py-4 w-8', {
    cursor: 'pointer',
  }),
  ({ selected }) => ({
    ...apply(
      selected
        ? 'bg-white font-semibold'
        : 'bg-blue-lightest font-regular text-grey-darkest'
    ),
    '&:hover': selected ? apply('bg-white') : { backgroundColor: '#f7fcff' },
  })
)
const CreateJobButton = styled(Button)(
  apply('flex flex-row bg-white p-2 mx-1 mr-6 items-center'),
  {
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': { backgroundColor: '#f7fcff' },
    '&:active': apply('bg-grey-lighter'),
  }
)

function Tabs({ selectedTab, setSelectedTab, filterJobs }) {
  const history = useHistory()
  const defaultFilters = {
    limit: 15,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  }

  const tabs = [
    {
      title: 'New',
      id: 'new',
      onClick: () => {
        setSelectedTab('new')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.PUBLISHED],
          sortBy: 'createdAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-new-tab',
    },
    {
      title: 'Engaged',
      id: 'engaged',
      onClick: () => {
        setSelectedTab('engaged')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.ENGAGED],
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-engaged-tab',
    },
    {
      title: 'Finished',
      id: 'finished',
      onClick: () => {
        setSelectedTab('finished')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.FINISHED],
          sortBy: 'finishedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-finished-tab',
    },
    {
      title: 'In Review',
      id: 'in_review',
      onClick: () => {
        setSelectedTab('in_review')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.IN_REVIEW],
          sortBy: 'finishedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-in-review-tab',
    },
    {
      title: 'Finalised',
      id: 'finalised',
      onClick: () => {
        setSelectedTab('finalised')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.FINALIZED],
          sortBy: 'finishedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-finalised-tab',
    },
    {
      title: 'Unfulfilled',
      id: 'unfulfilled',
      onClick: () => {
        setSelectedTab('unfulfilled')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.STARTED],
          sortBy: 'createdAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-unfulfilled-tab',
    },
    {
      title: 'Overdue',
      id: 'overdue',
      onClick: () => {
        setSelectedTab('overdue')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.PUBLISHED],
          sortBy: 'createdAt',
          sortOrder: 'asc',
          to: subDays(new Date(), 1),
        })
      },
      testId: 'jobs-table-overdue-tab',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
      onClick: () => {
        setSelectedTab('cancelled')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.CANCELLED],
          sortBy: 'cancelledAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-cancelled-tab',
    },
  ]
  return (
    <Container>
      <TabsContainer>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            selected={selectedTab === tab.id}
            onClick={tab.onClick}
            data-test-id={tab.testId}
          >
            {tab.title}
          </Tab>
        ))}
      </TabsContainer>
      <CanCreateJob>
        <CreateJobButton
          onClick={async () => {
            const { id } = await jobModule.createJob()
            history.push(`job/draft/${id}`)
          }}
        >
          <PlusIcon width={17} height={17} style={apply('mr-2')} />
          Create Job
        </CreateJobButton>
      </CanCreateJob>
    </Container>
  )
}

export { Tabs }
