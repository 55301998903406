import { prepend } from '@solta/ramda-extra'
import { connect } from '@solta/stateless'
import Select from 'react-select'

import { ORGANIZATION_ROLE_DISPLAY_NAMES } from 'constants/roles'
import { styled, apply } from 'lib/styled'
import { staffModule, filterRole$ } from 'modules/staff'

const Container = styled.div(apply('flex-1'))

const styles = {
  control: (_, state) => {
    const basicStyle = apply(
      'flex flex-row border-0 rounded-lg bg-grey-lighter text-lg'
    )
    const disabledStyle = state.isDisabled
      ? apply('bg-grey-lightest', {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        })
      : {}

    return { ...basicStyle, ...disabledStyle }
  },

  option: (provided, { isSelected }) => ({
    ...provided,
    ...apply('bg-grey-lightest', {
      '&:hover': apply('bg-grey-light'),
    }),
    ...apply(isSelected ? 'text-blue-light' : 'text-black'),
  }),

  dropdownIndicator: () => apply('p-0 px-2 relative', { top: 1 }),

  valueContainer: (provided) => ({
    ...provided,
    ...apply('px-3 py-2'),
  }),

  indicatorSeparator: () => apply('hidden'),

  singleValue: (provided) => ({
    ...provided,
    ...apply('text-black'),
  }),

  placeholder: (provided) => ({
    ...provided,
    ...apply('text-grey'),
  }),
}

const options = prepend(
  { value: undefined, label: 'Any role' },
  Object.entries(ORGANIZATION_ROLE_DISPLAY_NAMES).map(([value, label]) => ({
    label,
    value,
  }))
)

const onChange = ({ value: filterRole }) => {
  staffModule.filterStaff(null, { filterRole })
}

const ConnectedRoleGroupSelectField = connect(() => ({
  filterRole: filterRole$,
}))(RoleGroupSelectField)

function RoleGroupSelectField({
  id,
  name,
  label,
  type,
  containerProps,
  filterRole,
  ...props
}) {
  return (
    <Container {...containerProps}>
      <Select
        value={options.find(({ value }) => value === filterRole)}
        type={type}
        onChange={onChange}
        options={options}
        menuPlacement="auto"
        styles={styles}
        isSearchable={false}
        placeholder="Select Role"
        {...props}
      />
    </Container>
  )
}

export { ConnectedRoleGroupSelectField as RoleGroupSelectField }
