import {
  prop,
  defaultTo,
  isNotNil,
  isNotNilOrEmpty,
  omitBy,
  isNilOrEmpty,
} from '@solta/ramda-extra'
import { format } from 'date-fns'
import { normalize, schema } from 'normalizr'

import { adminApi, baseApi } from 'lib/http-client'

const getFormattedDateOrUndefined = (date) => {
  const result = isNotNilOrEmpty(date)
    ? format(new Date(date), 'yyyy-MM-dd hh:mm')
    : undefined

  return result
}

const omitNilOrEmpty = (payload) => omitBy(isNilOrEmpty, payload)

const jobEntity = new schema.Entity('jobs')

const JobService = {
  async list({
    query = '',
    statuses,
    limit = 10,
    next,
    sortBy,
    sortOrder,
    from,
    to,
    project,
  }) {
    const config = {
      searchParams: {
        q: query,
        limit,
        sortBy,
        sortOrder,
        statuses,
      },
    }

    if (isNotNil(project)) {
      config.searchParams.project = project
    }

    if (isNotNil(from)) {
      config.searchParams.from = format(new Date(from), 'yyyy-MM-dd')
    }

    if (isNotNil(to)) {
      config.searchParams.to = format(new Date(to), 'yyyy-MM-dd')
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await baseApi.get('organization-jobs', config).json()
    const normalized = normalize(items, [jobEntity])
    const entities = defaultTo({}, prop('jobs', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      sortOrder: paging.sortOrder,
    }
  },
  async read(id) {
    return baseApi.get(`organization-jobs/${id}`).json()
  },

  async publish({
    id,
    productId,
    clientId,
    pilotId,
    address,
    scheduledAt,
    description,
    instructions,
    projectId,
    radius,
  }) {
    const payload = {
      title: prop('place_name')(address),
      description,
      instructions,
      productId,
      scheduledAt: format(scheduledAt, 'yyyy-MM-dd hh:mm'),
      location: prop('geometry')(address),
      address: prop('place_name')(address),
      engagedBy: pilotId,
      orderedBy: clientId,
      projectId,
      radius,
    }

    const config = {
      json: payload,
    }

    return baseApi.patch(`organization-jobs/${id}/publish`, config).json()
  },

  async update(
    id,
    {
      scheduledAt,
      finishedAt,
      engagedAt,
      startedAt,
      location,
      pilotId,
      clientId,
      ...otherData
    }
  ) {
    const payload = {
      scheduledAt: getFormattedDateOrUndefined(scheduledAt),
      finishedAt: getFormattedDateOrUndefined(finishedAt),
      engagedAt: getFormattedDateOrUndefined(engagedAt),
      startedAt: getFormattedDateOrUndefined(startedAt),
      location: prop('geometry')(location),
      address: prop('place_name')(location),
      engagedBy: pilotId,
      orderedBy: clientId,
      ...otherData,
    }

    const config = {
      json: omitNilOrEmpty(payload),
    }

    return adminApi.patch(`organization-jobs/${id}`, config).json()
  },

  async cancel(id) {
    return baseApi.delete(`organization-jobs/${id}`)
  },

  finalize(id) {
    return adminApi.patch(`organization-jobs/${id}/finalize`)
  },

  sendUploadReminder(id) {
    return adminApi.post(`organization-jobs/${id}/upload-reminder/send`)
  },

  create() {
    return baseApi.post(`organization-jobs`).json()
  },
}

export { JobService }
