import { pathOr, map, path, prop } from '@solta/ramda-extra'
import { select } from '@solta/stateless'

import { applicationModule } from './module'

const { state$, effectState$ } = applicationModule

const applicationEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))
const inspectedEntity$ = select(state$, prop('inspectedEntity'))
const filterQuery$ = select(state$, (state) => state.filterQuery)

const applicationList$ = select(order$, applicationEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)
const inspectedApplication$ = select(
  inspectedEntity$,
  applicationEntities$,
  (id, entities) => prop(id, entities)
)

const fetchApplicationsStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchApplications', 'status'], effectState)
)

const isFetchingApplications$ = select(
  fetchApplicationsStatus$,
  (fetchApplicationsStatus) => fetchApplicationsStatus === 'pending'
)

const isInspectingApplication$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) => {
    const inspectApplicationStatus = pathOr(
      'pending',
      ['inspectApplication', inspectedEntity, 'status'],
      effectState
    )

    return inspectApplicationStatus === 'pending'
  }
)

const getIsManagingApplication$ = (id) =>
  select(effectState$, (effectState) => {
    const approveApplicationStatus = path(
      ['approveApplication', id, 'status'],
      effectState
    )
    const declineApplicationStatus = path(
      ['declineApplication', id, 'status'],
      effectState
    )

    const isApprovingApplication = approveApplicationStatus === 'pending'
    const isDecliningApplication = declineApplicationStatus === 'pending'

    return isApprovingApplication || isDecliningApplication
  })

const filterStatus$ = select(state$, prop('filterStatus'))

export {
  applicationList$,
  inspectedEntity$ as inspectedApplicationId$,
  inspectedApplication$,
  filterQuery$,
  isFetchingApplications$,
  isInspectingApplication$,
  getIsManagingApplication$,
  filterStatus$,
}
