/* eslint-disable camelcase */
import { pickThenRename, filter } from '@solta/ramda-extra'

import { DOCUMENT_TYPES } from 'constants/documents'
import { safelyParseDate } from 'utils/safelyParseDate'

const buildMapboxGeoJson = (coordinates, address) => ({
  geometry: {
    coordinates,
    type: 'Point',
  },
  place_name: address,
})

const getJobInfo = ({
  reference,
  createdAt,
  scheduledAt,
  product,
  description = 'No description',
  instructions = 'No instructions',
  engagedAt,
  startedAt,
  finishedAt,
  uploadCode,
  address,
  radius,
  project,
  location: jobLocation,
}) => ({
  reference,
  createdAt: safelyParseDate(createdAt),
  scheduledAt: safelyParseDate(scheduledAt),
  startedAt: safelyParseDate(startedAt),
  engagedAt: safelyParseDate(engagedAt),
  finishedAt: safelyParseDate(finishedAt),
  description,
  instructions,
  uploadCode,
  product,
  project,
  radius,
  location: buildMapboxGeoJson(jobLocation?.coordinates, address),
})

const getJobDocuments = (documents) => {
  const pilotUploadedDocuments = filter(
    (doc) => doc.uploadedByModel === 'Pilot',
    documents
  )
  const organizationUploadedDocuments = filter(
    (doc) =>
      doc.uploadedByModel !== 'Pilot' && doc.documentType === DOCUMENT_TYPES.JOB_ASSET,
    documents
  )
  return {
    uploadedByPilot: pilotUploadedDocuments,
    uploadedByOrganization: organizationUploadedDocuments,
  }
}

const getContactInfo = ({ engagedBy, orderedBy }) => {
  const pilot = pickThenRename(
    ['id', 'fullName', 'phoneNumber', 'email', 'pilotCategory'],
    {
      fullName: 'name',
      phoneNumber: 'mobile',
    },
    engagedBy ?? {}
  )

  const client = pickThenRename(
    ['id', 'fullName', 'phoneNumber', 'email'],
    {
      fullName: 'name',
      phoneNumber: 'mobile',
    },
    orderedBy ?? {}
  )

  return { pilot, client }
}

const getRatings = (ratings) => {
  const communication = ratings?.communication
  const mediaAndPhotoQuality = ratings?.mediaAndPhotoQuality
  const overall = ratings?.overall

  return { communication, mediaAndPhotoQuality, overall }
}

export default function (inspectedJob, jobDocumentList) {
  const {
    ratings,
    engagedBy,
    orderedBy,
    feedback = 'No Feedback',
    ...inspectedJobData
  } = inspectedJob

  const jobInfo = getJobInfo(inspectedJobData)
  const documents = getJobDocuments(jobDocumentList)
  const contactInfo = getContactInfo({ engagedBy, orderedBy })
  const ratingAndFeedback = { ...getRatings(ratings), feedback }

  return { jobInfo, documents, contactInfo, ratingAndFeedback }
}
