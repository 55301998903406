import CONSTANTS from '@solta/constants'
import { isNilOrEmpty, noop, values } from '@solta/ramda-extra'
import FS from 'filesize'
import { useField } from 'formik'
import { useState, useCallback } from 'react'

import { ErrorMessage } from 'components/common/ErrorMessage'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'
import { useFileUpload, createErrorMessage } from 'utils/hooks'

const Panel = styled.div(apply('relative flex flex-column'))

const TopBar = styled.div(apply('flex items-center justify-between mb-2'))

const Label = styled.label(
  apply('uppercase tracking-wide text-xs text-grey-dark flex-1 font-semibold')
)

const btnStyle = apply('text-xl mr-2 text-grey-darker text-red-light', {
  cursor: 'pointer',
  transition: 'opacity 150ms ease-in-out',
  '&:hover': {
    opacity: 0.4,
  },
})

const DeleteBtn = styled.i(btnStyle, ({ disabled }) => ({
  pointerEvents: disabled && 'none',
  color: disabled ? 'gray !important' : '',
}))

const DownloadBtn = styled.i(btnStyle, ({ disabled }) =>
  apply('ml-2', {
    pointerEvents: disabled && 'none',
    color: disabled ? 'gray !important' : '',
  })
)
const UploadBtn = styled.i(btnStyle, ({ disabled }) =>
  apply('ml-2', {
    pointerEvents: disabled && 'none',
    color: disabled ? 'gray !important' : '',
  })
)

const DataContainer = styled.div(
  apply('relative h-16 border-solid border-0 rounded-md bg-grey-100 py-2', {
    overflowY: 'auto',
  })
)

const Text = styled.span(apply('text-base text-blue-light py-1 px-4'))

const Item = styled.div(
  apply('flex items-center justify-between', {
    cursor: 'pointer',
    '&:hover': apply('bg-grey-lightest'),
  }),
  ({ selected }) =>
    selected &&
    apply('bg-white', {
      '&:hover': apply('bg-white'),
    })
)

const SpinnerContainer = styled.div(apply('absolute'), {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const Overlay = styled.div(
  apply('absolute bg-white', {
    opacity: 0.5,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  })
)

const variantMapper = {
  download: {
    icon: 'fas fa-cloud-download-alt',
    style: apply('text-teal'),
    allowUpload: false,
  },
  upload: {
    icon: 'fas fa-cloud-upload-alt',
    style: apply('text-blue-light'),
    allowUpload: true,
  },
}

// eslint-disable-next-line complexity
export function ComplianceDocumentsPanel({
  fileType,
  name,
  containerStyle,
  onDownload = noop,
  onUpload = noop,
  onDelete = noop,
  disabled,
  onRejected = noop,
  acceptFileType = `${values(CONSTANTS.FILE_MIME_TYPES)}`,
  isLoading = false,
  itemPrefix,
  limit,
  testId,
  canDelete = true,
  canUpload = true,
  canDownload = true,
}) {
  const [{ value: documents = [] }, { error }] = useField({
    name,
  })

  const { getRootProps, getInputProps } = useFileUpload({
    accept: acceptFileType,
    noDrag: true,
    noKeyboard: true,
    onDropRejected: useCallback((fileRejections) =>
      onRejected(name, createErrorMessage(fileRejections))
    ),
    disabled,
    onDropAccepted: useCallback((files) => onUpload(files, name)),
  })

  const [selectedFile, setSelectedFile] = useState({
    id: '',
    fileName: '',
  })

  const disableDownloadBtn = isNilOrEmpty(selectedFile.id) || isLoading
  const disableDeleteBtn = isNilOrEmpty(selectedFile.id) || isLoading

  return (
    <Panel style={containerStyle}>
      <TopBar>
        <div style={apply('flex')}>
          <Label>{fileType}</Label>
        </div>

        <div>
          {canDelete && (
            <DeleteBtn
              className="fas fa-trash-alt"
              onClick={() => {
                onDelete(selectedFile)
                setSelectedFile({ id: '', fileName: '' })
              }}
              disabled={disableDeleteBtn}
              data-test-id={`${testId}-delete-button`}
            />
          )}
          {canUpload && (
            <UploadBtn
              className={variantMapper.upload.icon}
              style={variantMapper.upload.style}
              disabled={isLoading || documents.length === limit}
              data-test-id={`${testId}-upload-button`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
            </UploadBtn>
          )}
          {canDownload && (
            <DownloadBtn
              className={variantMapper.download.icon}
              style={variantMapper.download.style}
              onClick={() => onDownload(selectedFile, name)}
              disabled={disableDownloadBtn}
              data-test-id={`${testId}-download-button`}
            />
          )}
        </div>
      </TopBar>

      <DataContainer>
        {documents.map(({ id, fileName, fileSize = 0 }) => (
          <Item
            key={id}
            selected={selectedFile.id === id}
            onClick={() => setSelectedFile({ id, fileName })}
          >
            <Text>
              {itemPrefix} - {fileName}
            </Text>
            <Text>{FS(fileSize)}</Text>
          </Item>
        ))}
        <SpinnerContainer hidden={!isLoading}>
          <Spinner size={64} thickness={7} />
        </SpinnerContainer>
        <Overlay hidden={!isLoading} />
      </DataContainer>
      <ErrorMessage error={error} visible={true} style={apply('text-sm')} />
    </Panel>
  )
}
