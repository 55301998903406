import { pathOr } from '@solta/ramda-extra'
import { connect } from '@solta/stateless'
import { useState, useLayoutEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Columns } from './Columns'
import { FilterBar } from './FilterBar'
import { Rows } from './Rows'
import { Tabs } from './Tabs'

import { Spinner } from 'components/common/Spinner'
import { Table } from 'components/common/withTable'
import { JOB_STATUSES } from 'constants/jobs'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import { jobModule, jobList$, isLoadingJobs$, jobFilters$ } from 'modules/job'

const Container = styled.div(
  apply('flex flex-column w-full bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const ButtonContainer = styled.div(apply('justify-end flex m-4'))

const { fetchJobs, filterJobs } = jobModule

const ConnectedJobTable = connect(() => ({
  jobList: jobList$,
  jobFilters: jobFilters$,
  isLoadingJobs: isLoadingJobs$,
}))(JobTable)

function JobTable({ jobList, jobFilters, isLoadingJobs }) {
  const {
    sortBy,
    paging: { startRow, next },
    limit,
  } = jobFilters
  const { role } = useIdentity()
  const history = useHistory()
  const location = useLocation()

  const tab = pathOr('new', ['state', 'tab'], location)
  const [selectedTab, setSelectedTab] = useState(tab)

  useLayoutEffect(() => {
    filterJobs(null, {
      statuses: tab === 'engaged' ? [JOB_STATUSES.ENGAGED] : [JOB_STATUSES.PUBLISHED],
      sortBy: 'createdAt',
      sortOrder: 'asc',
    })
  }, [])

  const columns = Columns(selectedTab, role)

  const data = Rows(jobList)

  const onSort = (sortBy, sortOrder) => filterJobs(null, { sortBy, sortOrder })

  const onTurnPage = ({ turnNext }) => fetchJobs(null, { turnPage: true, turnNext })

  const onRowClicked = (jobId) => history.push(`/job/${jobId}`)

  return (
    <Container>
      <ButtonContainer>
        <FilterBar selectedTab={selectedTab} />
      </ButtonContainer>

      <Tabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        filterJobs={filterJobs}
      />

      {isLoadingJobs && (
        <Spinner size={24} thickness={4} style={{ margin: 'auto 0' }} />
      )}

      <Table
        columns={columns}
        data={data}
        onClick={onRowClicked}
        sortBy={sortBy}
        startRow={startRow}
        next={next}
        limit={limit}
        onSort={onSort}
        turnPage={onTurnPage}
        domain={'jobs'}
      />
    </Container>
  )
}

export { ConnectedJobTable as JobTable }
