import { createModule } from '@solta/stateless'

import { PilotCategoryService } from './service'

import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  order: [],
})

const fetchPilotCategories = (module, { setError }) =>
  withTryCatch(
    async () => {
      const { entities, order } = await PilotCategoryService.list()

      module.setState({
        entities,
        order,
      })
    },
    { onError: (err) => setError(null, err) }
  )

const pilotCategoryModule = createModule({
  name: 'pilotCategory',
  initialState: INITIAL_STATE,
  decorators: {
    fetchPilotCategories,
  },
})

export { pilotCategoryModule }
