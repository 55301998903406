import { equals } from '@solta/ramda-extra'
import { memo } from 'react'

import { Dashboards } from 'components/Dashboards'
import { styled, apply } from 'lib/styled'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/vendors.scss'
import 'styles/index.css'
import { ConfigureToast } from 'lib/toaster'

const Container = styled.div(
  apply('h-full flex flex-column', {
    backgroundColor: '#F8F8F8',
  })
)

function App() {
  return (
    <Container>
      <Dashboards />
      <ConfigureToast />
    </Container>
  )
}

export default memo(App, equals)
