import { assoc, filter, map, pick, pipe } from '@solta/ramda-extra'
import * as Validator from 'yup'

const { string, object, array, number } = Validator
const longitudeSchema = number().min(-180).max(180)
const latitudeSchema = number().min(-90).max(90)
const validateCoordinates = ([long, lat] = []) =>
  longitudeSchema.isValidSync(long) && latitudeSchema.isValidSync(lat)
export const mapboxResultSchema = object({
  // eslint-disable-next-line camelcase
  place_name: string().required(),
  geometry: object({
    type: string().required().oneOf(['Point']),
    coordinates: array()
      .length(2)
      .required()
      .test(
        'is-valid-coordinates',
        'Please select a different address',
        validateCoordinates
      ),
  }),
})

// this makes sure we don't show invalid addresses (from our point of view) if mapbox doesn't stick to their API contracts https://docs.mapbox.com/api/search/geocoding/
const removeInvalidFeatures = (features = []) =>
  filter((r) => mapboxResultSchema.isValidSync(r), features)

// we only need 'type' and 'coordinates'. See backend models for job
const pluckRelevantGeometryProps = (feature = {}) =>
  assoc('geometry', pick(['type', 'coordinates'], feature?.geometry), feature)

export const preProcessResults = (features = []) =>
  pipe(removeInvalidFeatures, map(pluckRelevantGeometryProps))(features)
