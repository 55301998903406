/* eslint-disable import/no-cycle */
import { map, prop, path, pathOr, pick } from '@solta/ramda-extra'
import { select } from '@solta/stateless'

import { jobModule } from './module'

const { state$, effectState$ } = jobModule

const jobEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))

const filterQuery$ = select(state$, prop('filterQuery'))
const jobFilters$ = select(
  state$,
  pick([
    'filterQuery',
    'statuses',
    'paging',
    'sortBy',
    'sortOrder',
    'limit',
    'from',
    'to',
    'project',
  ])
)

const inspectedEntity$ = select(state$, prop('inspectedEntity'))

const jobList$ = select(order$, jobEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const inspectedJob$ = select(inspectedEntity$, jobEntities$, (id, entities) =>
  prop(id, entities)
)

const fetchJobsStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchJobs', 'status'], effectState)
)

const inspectJobStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectJob', inspectedEntity, 'status'], effectState)
)

const finalizeJobStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('idle', ['inspectJob', inspectedEntity, 'status'], effectState)
)

const sendUploadReminderStatus$ = select(
  inspectedEntity$,
  effectState$,
  (entity, effectState) =>
    pathOr('idle', ['sendUploadReminder', entity, 'status'], effectState)
)

const isLoadingJobs$ = select(fetchJobsStatus$, (status) => status === 'pending')

const isInspectingJobs$ = select(inspectJobStatus$, (status) => status === 'pending')
const isFinalizingJob$ = select(finalizeJobStatus$, (status) => status === 'pending')

export {
  jobList$,
  inspectedEntity$,
  inspectedJob$,
  filterQuery$,
  isLoadingJobs$,
  isInspectingJobs$,
  fetchJobsStatus$,
  isFinalizingJob$,
  sendUploadReminderStatus$,
  jobFilters$,
}
