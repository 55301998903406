import { prop, defaultTo, pick, pipe, isNilOrEmpty, omitBy } from '@solta/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi, http } from 'lib/http-client'
import { stringifyParams } from 'utils/stringifyParams'

const documentEntity = new schema.Entity('documents')

const DocumentService = {
  async fetchJobDocuments(id, filters = { limit: 100 }) {
    const searchParams = pipe(
      omitBy(isNilOrEmpty),
      pick(['q', 'limit', 'next', 'sortBy', 'sortOrder', 'documentTypes'])
    )(filters)

    const { items, paging } = await adminApi
      .get(`organization-jobs/${id}/documents?${stringifyParams(searchParams)}`)
      .json()

    const normalized = normalize(items, [documentEntity])
    const entities = defaultTo({}, prop('documents', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async downloadJobDocument(id, jobId) {
    const { signedUrl } = await adminApi
      .get(`organization-jobs/${jobId}/download-documents/${id}`)
      .json()

    return http.get(signedUrl).blob()
  },

  deleteJobDocument(id, jobId) {
    return adminApi.delete(`organization-jobs/${jobId}/documents/${id}`)
  },

  renameJobDocument(id, jobId, newFileName) {
    const config = {
      json: {
        fileName: newFileName,
      },
    }

    return adminApi.patch(`organization-jobs/${jobId}/documents/${id}`, config).json()
  },
}

export { DocumentService }
