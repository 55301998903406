import CONSTANTS from '@solta/constants'
import * as Validator from 'yup'

import radiusValidator from '../common/radiusValidator'

import { DOCUMENT_TYPES } from 'constants/documents'
import { ORGANIZATION_ROLES } from 'constants/roles'
import { mapboxResultSchema } from 'utils/hooks/preProcessResults'

const { string, number, object, date, array } = Validator
const requiredString = string().required('This field is required')
const optionalString = string().nullable().optional()
const requiredMongoId = string().matches(CONSTANTS.REGEX.MONGO_OBJECT_ID).required()
const complianceDocumentSchema = array()
  .length(1, 'Please upload 1 document')
  .required()
  .of(
    object({
      jobId: requiredMongoId,
      id: requiredMongoId,
      fileName: requiredString,
      fileSize: number().required(),
    })
  )

export const validationSchema = ({ minScheduledAtDate = new Date(), role } = {}) =>
  object({
    jobInfo: object({
      address: mapboxResultSchema.required(),
      radius: radiusValidator,
      scheduledAt: date()
        .min(
          // must reset the date time since user can type in today which will be convert to today at 00 00 00. While the dynamic minDate will be later in the same day. Yup also expects a date here so that's why we need to convert back to Date after reseting the time.
          new Date(minScheduledAtDate.setHours(0, 0, 0, 0)),
          'Please select today or a future date'
        )
        .required('Please select today or a future date')
        .typeError('Please enter a valid date'),
      description: requiredString,
      instructions: optionalString,
      product: object({
        id: requiredString,
        name: requiredString,
      }),
      project: object({
        reference: optionalString,
        id: optionalString,
      }).optional(),
      complianceDocuments: object({
        [DOCUMENT_TYPES.JOB_JSEA]: complianceDocumentSchema,
        [DOCUMENT_TYPES.JOB_TERMS]: complianceDocumentSchema,
        [DOCUMENT_TYPES.JOB_CASA]: complianceDocumentSchema,
      }),
    }),
    contactInfo: object({
      client: object({
        id: role === ORGANIZATION_ROLES.STAFF ? optionalString : requiredString,
        name: role === ORGANIZATION_ROLES.STAFF ? optionalString : requiredString,
        mobile: optionalString,
        email: optionalString,
      }),

      pilot: object({
        id: optionalString,
        name: optionalString,
        mobile: optionalString,
        email: optionalString,
      }),
    }),
  })
