import { connect } from '@solta/stateless'

import { Button as ButtonBase } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { JOB_STATUSES } from 'constants/jobs'
import { apply, styled } from 'lib/styled'
import toast from 'lib/toaster'
import { isFinalizingJob$, jobModule, inspectedJob$ } from 'modules/job'

const Container = styled.div(apply('flex justify-center'))

const btnStyle = apply('w-12 py-4 px-4 flex items-center justify-center')

const Button = styled(ButtonBase)(btnStyle)

const ConnectedActions = connect(() => ({
  isFinalizingJob: isFinalizingJob$,
  inspectedJob: inspectedJob$,
}))(Actions)

function Actions({ inspectedJob = {}, isFinalizingJob }) {
  const scrollToTop = () => {
    const scroll = document.querySelector('#scroll')
    if (scroll) scroll.scrollTop = 0
  }

  switch (inspectedJob.status) {
    case JOB_STATUSES.IN_REVIEW:
      return (
        <Container>
          <Button
            type="button"
            disabled={isFinalizingJob}
            onClick={async () => {
              if (!inspectedJob.project) {
                toast.warn('Please add a Project ID before finalizing')
                scrollToTop()
              } else {
                await jobModule.finalizeJob()
              }
            }}
          >
            {isFinalizingJob && (
              <Spinner size={20} thickness={3} style={apply('mr-4')} />
            )}
            Finalize Job
          </Button>
        </Container>
      )
    default:
      return null
  }
}

export { ConnectedActions as Actions }
