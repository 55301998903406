import { prop, path } from '@solta/ramda-extra'
import moment from 'moment'
import { useMemo } from 'react'

export const Rows = (jobList) =>
  useMemo(() =>
    jobList.map((job) => ({
      id: prop('id', job),
      reference: prop('reference', job),
      orderedBy: path(['orderedBy', 'fullName'], job),
      scheduledAt: moment(prop('scheduledAt', job)).format('DD/MM/YY hh:mm A'),
    }))
  )
