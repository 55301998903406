import { connect } from '@solta/stateless'
import { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ActionsBar } from './ActionsBar'
import { Columns } from './Columns'
import { FileRenameModal } from './FileRenameModal'
import { Rows } from './Rows'

import { DeleteFileModal } from 'components/common/DeleteFileModal'
import { Spinner } from 'components/common/Spinner'
import { Table } from 'components/common/withTable'
import { styled, apply } from 'lib/styled'
import {
  documentModule,
  jobDocumentList$,
  sortBy$,
  paging$,
  limit$,
  isLoadingJobDocuments$,
  isOperatingFile$,
} from 'modules/document'
import { jobModule, inspectedJob$ } from 'modules/job'
import { useFileModal } from 'utils/hooks'

const Container = styled.div(
  apply('flex flex-column w-full bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)
const Header = styled.div(apply('flex flex-row p-6 pb-1'))
const Title = styled.h2(apply('m-0 text-xl font-semibold'))

const ConnectedFileTable = connect(() => ({
  jobDocumentList: jobDocumentList$,
  sortBy: sortBy$,
  paging: paging$,
  limit: limit$,
  inspectedJob: inspectedJob$,
  isLoadingJobDocuments: isLoadingJobDocuments$,
  isOperatingFile: isOperatingFile$,
}))(FileTable)

function FileTable({
  sortBy,
  paging,
  limit,
  isLoadingJobDocuments,
  jobDocumentList,
  inspectedJob,
  isOperatingFile,
}) {
  const { id: jobId } = useParams()

  const [deleteFileModal, setDeleteFileModal] = useFileModal()
  const [renameFileModal, setRenameFileModal] = useFileModal()

  useLayoutEffect(() => {
    documentModule.fetchJobDocuments(null, jobId)
    jobModule.inspectJob(jobId)
  }, [])

  const isLoading = isLoadingJobDocuments || isOperatingFile

  const { startRow, next } = paging

  const columns = Columns()

  const data = Rows(jobDocumentList, { setRenameFileModal, setDeleteFileModal })

  const onSort = (sortBy, sortOrder) => {
    documentModule.setFilterParams(null, { sortBy, sortOrder })
    documentModule.fetchJobDocuments(null, jobId, { turnPage: false })
  }

  const onTurnPage = ({ turnNext }) => {
    documentModule.fetchJobDocuments(null, { turnPage: true, turnNext })
  }

  return (
    <Container>
      <ActionsBar
        setFilterParams={documentModule.setFilterParams}
        fetchJobDocuments={documentModule.fetchJobDocuments}
        inspectedJobId={jobId}
      />
      <Header>{inspectedJob && <Title>Job {inspectedJob?.reference}</Title>}</Header>

      {isLoading && <Spinner size={24} thickness={4} style={{ margin: 'auto 0' }} />}

      <Table
        columns={columns}
        data={data}
        sortBy={sortBy}
        startRow={startRow}
        next={next}
        limit={limit}
        onSort={onSort}
        turnPage={onTurnPage}
        domain={'files'}
      />

      <DeleteFileModal
        isOpen={deleteFileModal.isOpened}
        setIsOpen={(open) => setDeleteFileModal({ isOpened: open })}
        fileName={deleteFileModal.fileName}
        deleteHandler={() => documentModule.deleteJobDocument(deleteFileModal.fileId)}
      />

      <FileRenameModal
        isOpen={renameFileModal.isOpened}
        setIsOpen={(open) => setRenameFileModal({ isOpened: open })}
        fileName={renameFileModal.fileName}
        fileId={renameFileModal.fileId}
      />
    </Container>
  )
}

export { ConnectedFileTable as FileTable }
