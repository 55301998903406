import { connect } from '@solta/stateless'

import { SearchInput } from 'components/common/SearchInput'
import { apply } from 'lib/styled'
import { jobModule, filterQuery$ } from 'modules/job'

const ConnectedFilterInput = connect(() => ({
  filterQuery: filterQuery$,
}))(FilterInput)

const { filterJobs } = jobModule

function FilterInput({ filterQuery }) {
  return (
    <SearchInput
      value={filterQuery}
      placeholder="Search by reference"
      onChange={(filterQuery) => filterJobs(null, { filterQuery })}
      containerProps={{ style: apply('w-1/3') }}
    />
  )
}

export { ConnectedFilterInput as FilterInput }
