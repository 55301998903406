import { Switch, Route } from 'react-router-dom'

import { CreateJob } from './CreateJob'
import { JobDetails } from './JobDetails'
import { JobTable } from './JobTable'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { ProtectedRoute } from 'components/common/ProtectedRoute'
import { RESOURCES } from 'constants/resources'
import { usePermissions } from 'lib/permissions'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

function JobsManager() {
  const permissions = usePermissions()
  const canCreateJob = permissions.can('create', RESOURCES.JOB)

  return (
    <Container>
      <Switch>
        <Route exact path={['/', '/jobs']} component={JobTable} />
        <ProtectedRoute
          exact
          path="/job/draft/:id"
          component={CreateJob}
          allowed={canCreateJob}
        />
        <Route exact path="/job/:id" component={JobDetails} />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { JobsManager }
