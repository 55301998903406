export const LICENCE_TYPES = {
  REOC: 'licence_type_reoc',
  REPL: 'licence_type_repl',
  ARN: 'licence_type_arn',
  OTHER: 'licence_type_other',
}

export const LICENCE_TYPE_DISPLAY_NAMES = {
  [LICENCE_TYPES.REOC]: 'ReOC',
  [LICENCE_TYPES.REPL]: 'RePL',
  [LICENCE_TYPES.ARN]: 'ARN',
  [LICENCE_TYPES.OTHER]: 'Other',
}
