import { isNotNilOrEmpty, path, omit } from '@solta/ramda-extra'
import { createModule } from '@solta/stateless'
import { circle } from '@turf/turf'

import { UploadService } from '../upload'
import { UserService } from '../user'

import { PilotService } from './service'

import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  filterQuery: '',
  order: [],
  avatar: undefined,
  paging: {
    next: undefined,
  },
})

const fetchPilots = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = true } = {}) => {
      const { filterQuery, paging } = module.getState()
      const next = turnPage ? paging.next : undefined

      const {
        entities,
        order,
        next: newNext,
      } = await PilotService.list({
        query: filterQuery,
        next,
      })

      module.setState({
        entities,
        order,
        paging: {
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const inspectPilot = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      module.setState({
        inspectedEntity: id,
      })

      const entity = await PilotService.read(id)

      module.setState({
        entities: { [id]: entity },
      })
    },
    { errHandler: setError }
  )

const filterPilots = (module) => (query) => {
  module.setState({
    filterQuery: query,
  })

  module.fetchPilots(null, { turnPage: false })
}

const updatePilot = (module, { setError }) =>
  withTryCatch(
    async (id, payload) => {
      const { userDetails, pilotDetails } = payload
      const { entities, avatar } = module.getState()
      const { userId } = path([id], entities)

      if (isNotNilOrEmpty(avatar)) {
        userDetails.avatar = avatar
      }

      pilotDetails.addressLocation = pilotDetails.address.geometry
      pilotDetails.address = pilotDetails.address.place_name

      pilotDetails.serviceAreas = [
        // `turf` generates a `Feature` but we just want the `Polygon`
        circle(pilotDetails.addressLocation, pilotDetails.serviceRadius, {
          units: 'kilometers',
        }).geometry,
      ]

      const [updatedUser, updatedPilot] = await Promise.all([
        UserService.update(userId, userDetails),
        PilotService.update(id, pilotDetails),
      ])

      const filteredUser = omit(['authId', 'createdAt', 'devices'], updatedUser)
      const filteredPilot = omit(['userDetails'], updatedPilot)

      filteredPilot.address = {
        geometry: filteredPilot.addressLocation,
        // eslint-disable-next-line camelcase
        place_name: filteredPilot.address,
      }

      module.setState({
        entities: { [id]: { userDetails: filteredUser, ...filteredPilot } },
      })

      module.inspectPilot(id)
    },
    { errHandler: setError }
  )

const uploadAvatar = (module, { setError }) =>
  withTryCatch(
    async (_, file, pilotId) => {
      const { userId } = path(['entities', pilotId], module.getState())

      const { filename } = await UploadService.uploadFile(
        file,
        `users/${userId}/avatar-upload`,
        { fileName: file.name, fileSize: file.size }
      )

      module.setState({ avatar: filename })
    },
    { errHandler: setError }
  )

const resetUploadAvatar = (module) => () => {
  module.setState({ avatar: undefined })
}

const downloadDocument = (_, { setError }) =>
  withTryCatch(
    async (documentId, pilotId) => PilotService.downloadDocument(documentId, pilotId),
    { errHandler: setError }
  )

const pilotModule = createModule({
  name: 'pilot',
  initialState: INITIAL_STATE,
  decorators: {
    fetchPilots,
    inspectPilot,
    filterPilots,
    updatePilot,
    uploadAvatar,
    resetUploadAvatar,
    downloadDocument,
  },
})

export { pilotModule }
