import { createModule } from '@solta/stateless'

import { UserService } from './service'

import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  filterQuery: '',
  order: [],
  paging: {
    next: undefined,
  },
})

const fetchUsers = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = true } = {}) => {
      const { filterQuery, paging } = module.getState()
      const next = turnPage ? paging.next : undefined

      const {
        entities,
        order,
        next: newNext,
      } = await UserService.list({
        query: filterQuery,
        next,
      })

      module.setState({
        entities,
        order,
        paging: {
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const filterUsers = (module) => (query) => {
  module.setState({
    filterQuery: query,
  })

  module.fetchUsers(null, { turnPage: false })
}

const userModule = createModule({
  name: 'user',
  initialState: INITIAL_STATE,
  decorators: {
    fetchUsers,
    filterUsers,
  },
})

export { userModule }
