import { map, prop, pathOr } from '@solta/ramda-extra'
import { select } from '@solta/stateless'

import { pilotCategoryModule } from './module'

const { state$, effectState$ } = pilotCategoryModule

const pilotCategoriesEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))

const pilotCategoriesList$ = select(
  order$,
  pilotCategoriesEntities$,
  (order, entities) => map((id) => entities?.[id], order)
)

const fetchPilotCategoriesStatus$ = select(effectState$, (effectState) =>
  pathOr('idle', ['fetchPilotCategories', 'status'], effectState)
)

const isFetchingPilotCategories$ = select(
  fetchPilotCategoriesStatus$,
  (status) => status === 'pending'
)

export { pilotCategoriesList$, isFetchingPilotCategories$, pilotCategoriesEntities$ }
