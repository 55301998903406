const SERVICE_CATEGORIES = [
  {
    value: 'water_service',
    readableValue: 'Water',
    iconClass: 'fas fa-tint',
  },
  {
    value: 'energy_resource_service',
    readableValue: 'Energy & Resource',
    iconClass: 'fas fa-plug',
  },
  {
    value: 'environment_service',
    readableValue: 'Environment',
    iconClass: 'fas fa-seedling',
  },
  {
    value: 'property_building_service',
    readableValue: 'Property & Building',
    iconClass: 'fas fa-city',
  },
  {
    value: 'transport_service',
    readableValue: 'Transport',
    iconClass: 'fas fa-truck',
  },
]

export { SERVICE_CATEGORIES }
