import { useMemo } from 'react'

import { FolderCell } from './FolderCell'

export const Columns = () =>
  useMemo(() => [
    {
      Header: 'Reference',
      accessor: 'reference',
      disableSortBy: true,
      Cell: FolderCell,
    },
    { Header: 'Client', accessor: 'orderedBy' },
    { Header: 'Scheduled At', accessor: 'scheduledAt' },
    { Header: 'Last Modified At', accessor: 'lastModifiedAt' },
  ])
