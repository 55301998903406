import { isEmpty } from '@solta/ramda-extra'
import throttle from 'p-throttle'
import { useState } from 'react'

import { preProcessResults } from './preProcessResults'

import { CONFIG } from 'config'
import { http } from 'lib/http-client'

const mapboxApi = http.extend({
  prefixUrl: `${CONFIG.MAPBOX.API_URL}/`,
})

const GeocodingService = {
  forward: throttle({ limit: 5, interval: 1000 })(async (query) => {
    const searchParams = {
      country: 'AU,NZ',
      type: 'address',
      // eslint-disable-next-line camelcase
      access_token: CONFIG.MAPBOX.ACCESS_TOKEN,
    }

    const config = { searchParams }
    return mapboxApi.get(`geocoding/v5/mapbox.places/${query}.json`, config).json()
  }),
}

function useGeocoding() {
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState([])

  async function forwardGeocode(query) {
    GeocodingService.forward.abort()

    if (isEmpty(query)) {
      setResults([])
      return
    }

    setIsLoading(true)

    try {
      const featureCollection = await GeocodingService.forward(query)
      setResults(preProcessResults(featureCollection?.features))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  function resetResults() {
    setResults([])
  }

  return {
    isLoading,
    results,
    forwardGeocode,
    resetResults,
  }
}

export { useGeocoding }
