import { defaultTo } from '@solta/ramda-extra'
import { useField } from 'formik'
import TextAreaBase from 'react-textarea-autosize'

import { ErrorMessage } from 'components/common/ErrorMessage'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex flex-column'))
const TopBar = styled.div(apply('flex justify-between'))
const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const Input = styled(TextAreaBase)(
  apply('bg-grey-100 text-lg text-dark border-0  rounded-md px-3 py-2'),
  ({ readOnly }) => (readOnly ? apply('text-gray-700') : {}),
  ({ disabled }) =>
    disabled
      ? apply('bg-gray-400 border-gray-700 text-gray-800', {
          cursor: disabled ? 'default' : 'pointer',
        })
      : {}
)
const CharacterLimit = styled(Label)(apply('ml-a text-gray-400'))

function TextAreaField({
  name,
  id,
  label,
  containerStyle,
  characterLimit = 0,
  ...props
}) {
  const [{ value, onChange: onFieldChange, ...fieldProps }, { touched, error }] =
    useField({
      name,
      id,
      ...props,
    })

  const useCharacterLimit = characterLimit > 0

  function handleChange(event) {
    if (useCharacterLimit && event.target.value.length > characterLimit) {
      return
    }

    onFieldChange(event)
  }

  return (
    <Container style={containerStyle}>
      <TopBar>
        <Label htmlFor={name || id}>{label}</Label>
        {useCharacterLimit && (
          <CharacterLimit>
            {characterLimit - defaultTo('')(value).length}
          </CharacterLimit>
        )}
      </TopBar>
      <Input value={value} onChange={handleChange} {...fieldProps} {...props} />

      <ErrorMessage visible={touched} error={error} />
    </Container>
  )
}

export { TextAreaField }
