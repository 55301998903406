import { path } from '@solta/ramda-extra'
import { connect } from '@solta/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useMemo, useLayoutEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ContactInfo } from '../common'

import { JobInfo } from './JobInfo'
import { validationSchema } from './validationSchema'

import { CancelButton, CreateButton } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { DOCUMENT_TYPES } from 'constants/documents'
import { JOB_STATUSES } from 'constants/jobs'
import { ORGANIZATION_ROLES } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import { jobDocumentList$ } from 'modules/document'
import { jobModule, inspectedJob$ } from 'modules/job'

const Container = styled.div(
  apply('flex flex-column w-full bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const Scrollable = styled.div(
  apply('flex flex-column px-6', {
    overflow: 'auto',
  })
)

const Details = styled(Form)(apply('flex-1 flex flex-column'))
const Row = styled.div(apply('flex-1 flex flex-row my-2'))

const Connected = connect(() => ({
  inspectedJob: inspectedJob$,
  jobDocumentList: jobDocumentList$,
}))(CreateJob)

const minScheduledAtDate = new Date()

const emptyInitialValues = {
  jobInfo: {
    address: undefined,
    radius: undefined,
    scheduledAt: new Date(),
    description: undefined,
    instructions: undefined,
    product: {
      id: undefined,
      name: undefined,
    },
    project: { reference: undefined, id: undefined },
    complianceDocuments: {
      [DOCUMENT_TYPES.JOB_JSEA]: [],
      [DOCUMENT_TYPES.JOB_TERMS]: [],
      [DOCUMENT_TYPES.JOB_CASA]: [],
    },
  },

  contactInfo: {
    client: {
      id: undefined,
      name: undefined,
      mobile: '',
      email: '',
    },

    pilot: {
      id: undefined,
      name: undefined,
      mobile: '',
      email: '',
    },
  },
}

function CreateJob({ inspectedJob }) {
  const { role } = useIdentity()

  const history = useHistory()
  function cancelCreate() {
    history.push('/jobs')
  }
  const { id } = useParams()

  useLayoutEffect(() => {
    jobModule.inspectJob(id)
    return () => jobModule.reset()
  }, [])

  useLayoutEffect(() => {
    if (inspectedJob && inspectedJob.status !== JOB_STATUSES.DRAFT) {
      history.push(`/job/${id}`)
    }
  }, [inspectedJob])

  const memoizedValidationSchema = useMemo(
    () => validationSchema({ minScheduledAtDate, role }),
    []
  )

  if (!inspectedJob) {
    return (
      <Container>
        <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
      </Container>
    )
  }

  async function handleSubmit(values) {
    const productId = path(['jobInfo', 'product', 'id'])(values)
    const clientId = path(['contactInfo', 'client', 'id'])(values)
    const pilotId = path(['contactInfo', 'pilot', 'id'])(values)
    const projectId = path(['jobInfo', 'project', 'id'])(values)

    const {
      jobInfo: { address, radius, scheduledAt, description, instructions } = {},
    } = values

    const { id } = await jobModule.publishJob(null, {
      productId,
      clientId,
      pilotId,
      address,
      radius,
      scheduledAt,
      description,
      instructions,
      projectId,
    })

    history.push(`/job/${id}`)
  }

  return (
    <Container>
      <Scrollable>
        <FormProvider
          enableReinitialize
          initialValues={emptyInitialValues}
          validationSchema={memoizedValidationSchema}
          onSubmit={handleSubmit}
        >
          <Details>
            <JobInfo minScheduledAtDate={minScheduledAtDate} />
            <ContactInfo
              isEditing
              editableClient={role === ORGANIZATION_ROLES.ADMIN}
              editablePilot={role === ORGANIZATION_ROLES.ADMIN}
            />

            <Row style={apply('justify-end my-4')}>
              <CancelButton onClick={cancelCreate}>Cancel</CancelButton>
              <CreateButton style={apply('ml-4')} type="submit">
                Publish
              </CreateButton>
            </Row>
          </Details>
        </FormProvider>
      </Scrollable>
    </Container>
  )
}

export { Connected as CreateJob }
