import { prop } from '@solta/ramda-extra'
import { select } from '@solta/stateless'

import { authModule } from './module'

const { state$ } = authModule

const isBanned$ = select(state$, prop('isBanned'))

export { isBanned$ }
