import { isNil, pick } from '@solta/ramda-extra'
import { connect } from '@solta/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { validationSchema } from '../validationSchema'

import { ProductDetailsForm } from './ProductDetailsForm'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { TabEditing } from 'components/common/TabEditing'
import { CanUpdateProduct } from 'lib/permissions'
import { styled, apply } from 'lib/styled'
import {
  productModule,
  inspectedProduct$,
  isInspectingProduct$,
  isUploadingImage$,
} from 'modules/product'

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const Scrollable = styled.div(
  apply('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)

const ConnectedProductDetails = connect(() => ({
  isInspectingProduct: isInspectingProduct$,
  isUploadingImage: isUploadingImage$,
  inspectedProduct: inspectedProduct$,
}))(ProductDetails)

function ProductDetails({
  isInspectingProduct,
  isUploadingImage,
  inspectedProduct,
  ...props
}) {
  const [isEditing, setIsEditing] = useState(false)

  const { id } = useParams()

  useEffect(() => {
    setIsEditing(false)
  }, [id])

  useEffect(() => {
    productModule.inspectProduct(id)
  }, [id])

  if (isNil(inspectedProduct)) return null

  return (
    <Container {...props}>
      <FormProvider
        id="productDetails"
        enableReinitialize
        initialValues={pick(
          ['name', 'description', 'serviceCategory', 'pricing'],
          inspectedProduct
        )}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await productModule.updateProduct(inspectedProduct.id, values)
          setIsEditing(false)
        }}
      >
        <Loader
          loading={isInspectingProduct}
          renderPlaceholder={
            <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
          }
        >
          <Scrollable>
            <Form>
              <Header>
                <Title>Product Details</Title>
                <CanUpdateProduct>
                  <TabEditing
                    style={{ marginLeft: 'auto' }}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    disabled={isUploadingImage}
                  />
                </CanUpdateProduct>
              </Header>

              <ProductDetailsForm
                id={inspectedProduct.id}
                isEditing={isEditing}
                productImageSrc={inspectedProduct?.productImage}
              />
            </Form>
          </Scrollable>
        </Loader>
      </FormProvider>
    </Container>
  )
}

export { ConnectedProductDetails as ProductDetails }
