/* eslint-disable complexity */
import { AbilityBuilder, Ability } from '@casl/ability'

import { RESOURCES } from 'constants/resources'
import { ROLES } from 'constants/roles'

const { PILOT_APPLICATION, PILOT_DETAILS, JOB, PRODUCT, FILE, STAFF } = RESOURCES

// 'manage' equals 'CRUD'
export function defineAbilityFor(role) {
  const { can, cannot, build } = new AbilityBuilder(Ability)

  switch (role) {
    case ROLES.STAFF:
      can('read', PILOT_APPLICATION)
      can('read', PILOT_DETAILS)
      can(['read', 'create', 'update'], JOB)
      can('read', PRODUCT)
      can('read', FILE)
      break
    case ROLES.ADMIN:
      can(['read', 'update', 'delete'], PILOT_APPLICATION)
      can(['read', 'update', 'delete'], PILOT_DETAILS)
      can('manage', JOB)
      can('manage', PRODUCT)
      can('manage', FILE)
      can('manage', STAFF)
      break
    default:
      cannot('manage', 'all')
  }

  return build()
}
