import { connect } from '@solta/stateless'
import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { FilterInput } from './FilterInput'
import { ProductListItem } from './ProductListItem'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { Button } from 'components/common/Button'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { CanCreateProduct } from 'lib/permissions'
import { styled, apply } from 'lib/styled'
import {
  productModule,
  productList$,
  inspectedEntity$,
  isLoadingProducts$,
} from 'modules/product'

const Container = styled.div(
  apply('flex flex-column w-1/4 min-w-10 max-w-24 mr-6 bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const InputContainer = styled.div(
  apply('flex justify-between p-4 border-0 border-b-1 border-solid border-grey-lighter')
)

const FilterInputContainer = styled.div(apply('flex-grow-1'))

const CreateProductButton = styled(Button)(
  apply('flex justify-center flex-row p-2 items-center h-full ml-4', {
    width: '45px',
  })
)

const List = styled.ol(
  apply('p-0 m-0', {
    overflow: 'auto',
    listStyleType: 'none',
  })
)

const ConnectedProductList = connect(() => ({
  productList: productList$,
  isLoadingProducts: isLoadingProducts$,
  inspectedEntity: inspectedEntity$,
}))(ProductList)

const { fetchProducts } = productModule

function ProductList({ isLoadingProducts, productList, inspectedEntity, ...props }) {
  const history = useHistory()

  useLayoutEffect(() => {
    fetchProducts(null)
  }, [])

  return (
    <Container {...props}>
      <InputContainer>
        <FilterInputContainer>
          <FilterInput />
        </FilterInputContainer>
        <CanCreateProduct>
          <CreateProductButton
            onClick={() => history.push(`/product/create`)}
            data-test-id="create-product-btn"
          >
            <PlusIcon width={17} height={17} style={apply('text-blue-700')} />
          </CreateProductButton>
        </CanCreateProduct>
      </InputContainer>

      <Loader
        loading={isLoadingProducts}
        renderPlaceholder={<Spinner size={32} thickness={6} style={apply('mt-4')} />}
      >
        <List>
          {productList.map((product) => (
            <ProductListItem
              key={product.id}
              isInspected={product.id === inspectedEntity}
              product={product}
              onClick={() => history.push(`/product/${product.id}`)}
            />
          ))}
        </List>
      </Loader>
    </Container>
  )
}

export { ConnectedProductList as ProductList }
