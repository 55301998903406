import { NavigationBar } from 'components/common/withNavigation/NavigationBar'
import { CONFIG } from 'config'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('h-full flex', {
    backgroundColor: '#F8F8F8',
  })
)
export const PilotErrorPage = () => (
  <div style={apply('h-full', { overflow: 'hidden' })}>
    <NavigationBar title="Admin Portal" />
    <Container>
      <div
        style={apply('flex flex-column items-center text-lg font-semibold', {
          margin: 'auto',
        })}
      >
        <p>It seems that you don&apos;t have permissions to access this application</p>
        <p>If you&apos;re a pilot, please download the pilot app from AppStore</p>
        <a
          href={CONFIG.PILOT_APP_APPSTORE_URL ?? '#'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-app-store-ios fa-6x" />
        </a>
      </div>
    </Container>
  </div>
)
