import { saveAs } from 'file-saver'

import { ReactComponent as Download } from 'assets/feathers/download.svg'
import { ReactComponent as Edit } from 'assets/feathers/edit.svg'
import { ReactComponent as Trash } from 'assets/feathers/trash.svg'
import { Button } from 'components/common/Button'
import { DOCUMENT_TYPES } from 'constants/documents'
import { CanDeleteFile, CanReadFile, CanUpdateFile } from 'lib/permissions'
import { styled, apply } from 'lib/styled'
import { documentModule } from 'modules/document'

const Cell = styled.div(apply('flex items-center'))
const ActionButton = styled(Button)(
  apply('flex flex-row items-center min-w-1 rounded-full p-2 my-2 mr-4'),
  {
    transition: 'background-color 0.2s',
    '&:hover': apply('rounded-full bg-grey-light', { fill: '#000', stroke: '#000' }),
    '&:active': apply('bg-grey-lighter'),
  },
  ({ disabled }) => ({ cursor: disabled ? 'not-allowed' : 'pointer' })
)

const onFileDeleted = ({ id: selectedDocId, fileName }, setDeleteFileModal) => {
  setDeleteFileModal({ isOpened: true, fileName, fileId: selectedDocId })
}

const onFileDownload = async ({ id: selectedDocId, fileName }) => {
  saveAs(await documentModule.downloadJobDocument(selectedDocId), fileName)
}

const COMPLIANCE_DOC_TYPES = [
  DOCUMENT_TYPES.JOB_CASA,
  DOCUMENT_TYPES.JOB_JSEA,
  DOCUMENT_TYPES.JOB_TERMS,
]

const ActionsCell = ({ rowData, setDeleteFileModal, setRenameFileModal }) => {
  const { name: fileName, id, documentType } = rowData
  return (
    <Cell>
      <CanUpdateFile>
        <ActionButton
          onClick={() =>
            setRenameFileModal({
              isOpened: true,
              fileName,
              fileId: id,
            })
          }
        >
          <Edit width={15} height={15} />
        </ActionButton>
      </CanUpdateFile>
      <CanReadFile>
        <ActionButton onClick={() => onFileDownload({ id, fileName })}>
          <Download width={15} height={15} />
        </ActionButton>
      </CanReadFile>
      <CanDeleteFile>
        <ActionButton
          disabled={COMPLIANCE_DOC_TYPES.includes(documentType)}
          onClick={() => onFileDeleted({ id, fileName }, setDeleteFileModal)}
        >
          <Trash width={15} height={15} />
        </ActionButton>
      </CanDeleteFile>
    </Cell>
  )
}

export { ActionsCell }
