import { connect } from '@solta/stateless'
import { useFormikContext as useFormContext } from 'formik'
import { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { JobDocuments } from '../JobDocuments'

import { ReactComponent as ExclamationCircleIcon } from 'assets/exclamation-circle.svg'
import { DateField } from 'components/common/DateField'
import { DeleteFileModal } from 'components/common/DeleteFileModal'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { LocationField } from 'components/common/LocationField'
import { PriceBlock } from 'components/common/PriceBlock'
import { SelectField } from 'components/common/SelectField'
import { TextAreaField } from 'components/common/TextAreaField'
import { AreaOfInterest, ComplianceSection } from 'components/jobs/common'
import { DOCUMENT_TYPES } from 'constants/documents'
import { JOB_STATUSES } from 'constants/jobs'
import { apply, styled } from 'lib/styled'
import { documentModule } from 'modules/document'
import { productModule, productList$, filterQuery$ } from 'modules/product'
import { projectModule, projectList$ } from 'modules/project'
import { useFileModal } from 'utils/hooks'

const Container = styled.div(apply('mb-12'))
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))

const Row = styled.div(
  apply('flex flex-row my-2 items-center', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)

const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

const ConnectedJobInfo = connect(() => ({
  productList: productList$,
  projectList: projectList$,
  filterQuery: filterQuery$,
}))(JobInfo)

function JobInfo({ productList, projectList, jobReference, isEditing, jobStatus }) {
  const [deleteFileModal, setDeleteFileModal] = useFileModal()
  const {
    setFieldValue,
    values: {
      jobInfo: { radius, location },
    },
  } = useFormContext()

  const coordinates = location?.geometry?.coordinates

  const productOptions = productList
    .filter((product) => product.isActive)
    .map(({ id, name: label }) => ({
      id,
      value: label,
      label,
    }))
  const projectOptions = projectList.map(({ id, reference }) => ({
    id,
    value: reference,
    label: reference,
  }))

  const { id } = useParams()
  useLayoutEffect(() => {
    productModule.fetchProducts(null)
    projectModule.fetchProjects(null)
    // we use filterJobDocuments instead of fetchJobDocuments to be explicit about fetching ALL job document types.
    documentModule.filterJobDocuments(id, {
      documentTypes: [
        DOCUMENT_TYPES.JOB_TERMS,
        DOCUMENT_TYPES.JOB_JSEA,
        DOCUMENT_TYPES.JOB_CASA,
        DOCUMENT_TYPES.JOB_ASSET,
      ],
    })

    return () => documentModule.reset()
  }, [])

  const jobInReviewOrFinalized = [
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.FINALIZED,
  ].includes(jobStatus)

  const projectLabel = `Project ID ${jobInReviewOrFinalized ? '' : '(optional)'}`

  const jobCompleted = [
    JOB_STATUSES.FINISHED,
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.FINALIZED,
  ].includes(jobStatus)

  return (
    <Container>
      <Header>
        <Title>Job Info</Title>
      </Header>

      <Details>
        <Column>
          <Row>
            <FormInput
              disabled
              name="jobInfo.reference"
              type="text"
              label="Reference"
            />

            <DateField
              disabled={true}
              name="jobInfo.createdAt"
              type="text"
              label="Created At"
              autoOk
            />
          </Row>

          <Row>
            <SelectField
              name="jobInfo.product.name"
              label="Product Name"
              isDisabled={!isEditing}
              onChange={({ id, value: name }) => {
                setFieldValue('jobInfo.product', { id, name })
              }}
              options={productOptions}
              placeholder="Search and Select Product"
              onInputChange={productModule.filterProducts}
              containerProps={{ style: apply('mr-3') }}
            />

            <DateField
              disabled={!isEditing}
              name="jobInfo.scheduledAt"
              type="text"
              label="Scheduled At"
              autoOk
            />
          </Row>

          <Row style={{ width: 'calc(50% - 6px)' }}>
            <SelectField
              name="jobInfo.project.reference"
              label={projectLabel}
              labelIcon={
                <ExclamationCircleIcon
                  {...apply('h-1 w-1', {
                    stroke: '#334155',
                  })}
                  data-tip="Project ID is only needed before finalising a job"
                  data-type="info"
                />
              }
              creatable
              isDisabled={!isEditing}
              optional
              emptyOption={{ id: undefined, reference: undefined }}
              onChange={({ id, value: reference }, { action }) => {
                if (action === 'create-option') {
                  projectModule.createProject(null, reference)
                }

                setFieldValue('jobInfo.project', { id, reference })
              }}
              options={projectOptions}
            />
          </Row>

          <Row>
            <LocationField
              name="jobInfo.location"
              label="Location"
              disabled={!isEditing}
            />
          </Row>

          <Row style={apply('w-1/4')}>
            <FormInput
              name="jobInfo.radius"
              label="Radius (meters)"
              disabled={!isEditing}
            />
          </Row>

          <AreaOfInterest coordinates={coordinates} radius={radius} />

          {jobCompleted && <PriceBlock isEditing={isEditing} style={apply('my-4')} />}

          <TextAreaField
            disabled={!isEditing}
            name="jobInfo.description"
            label="description"
            containerStyle={apply('mt-4 mr-4 w-full')}
            minRows={6}
          />
          <TextAreaField
            disabled={!isEditing}
            name="jobInfo.instructions"
            label="Instructions (optional)"
            containerStyle={apply('mt-4 mr-4 w-full')}
            minRows={6}
          />

          <Row>
            <DateField
              disabled
              name="jobInfo.engagedAt"
              type="text"
              label="Engaged At"
              inputStyles={{ padding: '6px 0' }}
              autoOk
              rootStyle={apply('pr-4')}
            />
            <FormInput
              disabled
              name="jobInfo.uploadCode"
              type="text"
              label="upload code"
            />
          </Row>

          {/* we force the component to remount since the form resets on submit, clearing out the docs inside formik state */}
          <ComplianceSection key={isEditing} />

          {jobCompleted && (
            <>
              <Header>
                <Title>Job Asssets</Title>
              </Header>
              <JobDocuments
                setDeleteFileModal={setDeleteFileModal}
                downloadJobDocument={documentModule.downloadJobDocument}
                jobReference={jobReference}
              />
              <DeleteFileModal
                isOpen={deleteFileModal.isOpened}
                setIsOpen={(open) => setDeleteFileModal({ isOpened: open })}
                fileName={deleteFileModal.fileName}
                deleteHandler={() =>
                  documentModule.deleteJobDocument(deleteFileModal.fileId)
                }
              />
            </>
          )}
        </Column>
      </Details>

      <ReactTooltip />
    </Container>
  )
}

export { ConnectedJobInfo as JobInfo }
