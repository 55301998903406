import { connect } from '@solta/stateless'
import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Columns } from './Columns'
import { FilterInput } from './FilterInput'
import { Rows } from './Rows'

import { Spinner } from 'components/common/Spinner'
import { Table } from 'components/common/withTable'
import { styled, apply } from 'lib/styled'
import { jobModule, jobList$, isLoadingJobs$, jobFilters$ } from 'modules/job'

const Container = styled.div(
  apply('flex flex-column w-full bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)
const InputContainer = styled.div(
  apply('px-6 py-4 border-0 border-b-1 border-solid border-grey-lighter')
)

const { fetchJobs, filterJobs } = jobModule

const ConnectedJobFileTable = connect(() => ({
  jobList: jobList$,
  jobFilters: jobFilters$,
  isLoadingJobs: isLoadingJobs$,
}))(JobFileTable)

function JobFileTable({ jobList, isLoadingJobs, jobFilters }) {
  const {
    sortBy,
    paging: { startRow, next },
    limit,
  } = jobFilters

  useLayoutEffect(() => {
    filterJobs(null, {
      sortBy: 'createdAt',
      sortOrder: 'asc',
    })
  }, [])

  const history = useHistory()

  const columns = Columns()

  const data = Rows(jobList)

  const onSort = (sortBy, sortOrder) => filterJobs(null, { sortBy, sortOrder })

  const onTurnPage = ({ turnNext }) => fetchJobs(null, { turnPage: true, turnNext })

  const onRowClicked = (jobId) => history.push(`/file/${jobId}`)

  return (
    <Container>
      <InputContainer>
        <FilterInput />
      </InputContainer>

      {isLoadingJobs && (
        <Spinner size={24} thickness={4} style={{ margin: 'auto 0' }} />
      )}

      <Table
        columns={columns}
        data={data}
        onClick={onRowClicked}
        sortBy={sortBy}
        startRow={startRow}
        next={next}
        limit={limit}
        onSort={onSort}
        turnPage={onTurnPage}
        domain={'files'}
        style={{ cursor: 'pointer' }}
      />
    </Container>
  )
}

export { ConnectedJobFileTable as JobFileTable }
