import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { Field, useField } from 'formik'

import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-column mx-3'))
const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const Input = styled(Field)(
  apply('bg-grey-100 text-lg text-black border-0 rounded-md px-3 py-2', {
    // NOTE: The :read-only pseudo-selector in CSS
    //       will match both `readonly` and `disabled` elements.
    '&:read-only': {
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
    },
  })
)

const ErrorMessage = styled.div(apply('mt-2 text-red text-sm'))

function FormInput({ id, name, label, className, containerProps, testId, ...props }) {
  // eslint-disable-next-line no-unused-vars
  const [_, { touched, error }] = useField({ name, id, ...props })

  return (
    <Container className={className} {...containerProps}>
      {isNotNilOrEmpty(label) && <Label htmlFor={name}>{label}</Label>}
      <Input name={name} {...props} data-test-id={testId} id={id ?? name} />

      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}

export { FormInput }
