import { connect } from '@solta/stateless'
import { saveAs } from 'file-saver'
import { useFormikContext } from 'formik'

import { FilePanel } from 'components/common/FilePanel'
import { DOCUMENT_TYPES } from 'constants/documents'
import { styled, apply } from 'lib/styled'
import { documentModule, isOperatingFile$ } from 'modules/document'

const Container = styled.div(apply('flex flex-row my-2 items-center justify-between'))

const ConnectedJobDocuments = connect(() => ({
  isOperatingFile: isOperatingFile$,
}))(JobDocuments)

function JobDocuments({
  setDeleteFileModal,
  downloadJobDocument,
  jobReference,
  isOperatingFile,
}) {
  const { setFieldError } = useFormikContext()

  const onFileDeleted = ({ id: selectedDocId, fileName }) => {
    setDeleteFileModal({ isOpened: true, fileName, fileId: selectedDocId })
  }
  const onFileDownload = async ({ id: selectedDocId, fileName }) => {
    saveAs(await downloadJobDocument(selectedDocId), fileName)
  }

  const onFileUpload = async ([file], fieldName) => {
    await documentModule.uploadJobDocument(null, {
      file,
      documentType: DOCUMENT_TYPES.JOB_ASSET,
    })
    setFieldError(fieldName, undefined)
  }

  const onRejected = (fieldName, errorMsg) => setFieldError(fieldName, errorMsg)

  return (
    <Container>
      <FilePanel
        entity="Pilot"
        containerStyle={apply('mr-8 flex-1')}
        type="download"
        name="documents.uploadedByPilot"
        onDownload={onFileDownload}
        onDelete={onFileDeleted}
        itemPrefix={jobReference}
        isLoading={isOperatingFile}
      />
      <FilePanel
        entity="GHD"
        containerStyle={apply('flex-1')}
        type="upload"
        name="documents.uploadedByOrganization"
        onDelete={onFileDeleted}
        onUpload={onFileUpload}
        onDownload={onFileDownload}
        onRejected={onRejected}
        itemPrefix={jobReference}
        isLoading={isOperatingFile}
      />
    </Container>
  )
}

export { ConnectedJobDocuments as JobDocuments }
