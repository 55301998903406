import { isNil, omitBy } from '@solta/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi, http } from 'lib/http-client'

const applicationSchema = new schema.Entity('applications')

const ApplicationService = {
  async list({ query = '', status, limit = 100, next, sortBy }) {
    const searchParams = omitBy(isNil, {
      q: query,
      status,
      limit,
      next,
      sortBy,
    })

    const { items, paging } = await adminApi
      .get('pilot-applications', { searchParams })
      .json()
    const normalized = normalize(items, [applicationSchema])
    const entities = normalized.entities?.applications ?? {}
    const order = normalized.result ?? []

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async read(id) {
    return adminApi.get(`pilot-applications/${id}`).json()
  },

  async approve(applicationId, pilotCategoryId) {
    return adminApi
      .put(`pilot-applications/${applicationId}`, { json: { pilotCategoryId } })
      .json()
  },

  async reject(id) {
    return adminApi.delete(`pilot-applications/${id}`).json()
  },

  async downloadDocument(id, applicationId) {
    const { signedUrl } = await adminApi
      .get(`pilot-applications/${applicationId}/documents/${id}`)
      .json()

    return http.get(signedUrl).blob()
  },
}

export { ApplicationService }
