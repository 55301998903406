import { isNotNilOrEmpty, isNotNil } from '@solta/ramda-extra'
import { Formik as FormProvider, Form } from 'formik'

import { FormInput as FormInputBase } from 'components/common/FormInput'
import { CircledMarkerLayer, Map } from 'components/common/Map'
import { styled, apply } from 'lib/styled'

const Container = styled.section()
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const Details = styled(Form)(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex flex-row my-2', {
    flex: 'auto',
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

function ServiceArea({ address, serviceRadius, coordinates }) {
  return (
    <Container>
      <Header>
        <Title>Service Area</Title>
      </Header>

      <FormProvider
        enableReinitialize
        initialValues={{
          address,
          serviceRadius: isNotNil(serviceRadius)
            ? `${serviceRadius} km`
            : 'Unspecified',
        }}
      >
        <Details>
          <Column>
            <Row>
              <FormInput
                readOnly
                name="address"
                type="text"
                label="Service Location"
                containerProps={{ style: { flexGrow: 4 } }}
              />

              <FormInput
                readOnly
                name="serviceRadius"
                type="text"
                label="Survey radius"
              />
            </Row>

            <Row style={apply('flex-column')}>
              <Map
                centerCoords={coordinates ?? [0, 0]}
                style="mapbox://styles/mapbox/streets-v9"
                containerStyle={apply(
                  'rounded-lg border-0 border-b-2 border-solid border-grey',
                  { height: '60vh' }
                )}
                zoom={{ 50: [8], 100: [7], 150: [6], 200: [6] }[serviceRadius]}
                type="circled-marker"
              >
                <CircledMarkerLayer
                  units="kilometres"
                  markerCoords={coordinates}
                  radius={serviceRadius}
                  visible={isNotNilOrEmpty(coordinates)}
                />
              </Map>
            </Row>
          </Column>
        </Details>
      </FormProvider>
    </Container>
  )
}

export { ServiceArea }
