import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { createModule } from '@solta/stateless'

import { UploadService } from '../upload'

import { ProductService } from './service'

import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  order: [],
  paging: {
    next: undefined,
  },
  image: {
    file: undefined,
    filename: undefined,
  },
})

const fetchProducts = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = true } = {}) => {
      const { filterQuery, paging } = module.getState()
      const next = turnPage ? paging.next : undefined

      const {
        entities,
        order,
        next: newNext,
      } = await ProductService.list({
        query: filterQuery,
        next,
      })

      module.setState({
        entities,
        order,
        paging: {
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const inspectProduct = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      module.setState({
        inspectedEntity: id,
      })
      const entity = await ProductService.read(id)

      module.setState({
        entities: { [id]: entity },
      })
    },
    { errHandler: setError }
  )

const updateProduct = (module, { setError }) =>
  withTryCatch(
    async (id, payload) => {
      const {
        image: { filename },
      } = module.getState()

      const updateProps = payload
      if (isNotNilOrEmpty(filename)) {
        updateProps.productImage = filename
      }

      const updatedProduct = await ProductService.update(id, updateProps)

      module.setState({
        entities: { [id]: updatedProduct },
      })

      module.inspectProduct(id)
    },
    { errHandler: setError }
  )

const setImageFile = (module) => (_, file) => {
  module.setState({ image: { file, filename: undefined } })
}

const uploadImage = (module, { setError }) =>
  withTryCatch(
    async (_, file, productId) => {
      const { filename } = await UploadService.uploadFile(
        file,
        `products/${productId}/image-upload`,
        { fileName: file.name, fileSize: file.size }
      )

      module.setState({ image: { file, filename } })
    },
    { errHandler: setError }
  )

const filterProducts = (module) => (query) => {
  module.setState({
    filterQuery: query,
  })

  module.fetchProducts(null, { turnPage: false })
}

const createProduct = (module, { setError }) =>
  withTryCatch(
    async (_, payload) => {
      const { image } = module.getState()

      const product = await ProductService.create({
        ...payload,
        productImage: image.file.name,
      })

      await module.uploadImage(null, image.file, product.id)

      const {
        image: { filename },
      } = module.getState()

      // We need to upload the image to determine the image filename however we need to
      // specify the image filename to create a product (to upload the image)
      // so here we call `ProductService.update` to update `productImage` to
      // the correct filename stored in `imageFilename`.
      await ProductService.update(product.id, { productImage: filename })

      return product
    },
    { errHandler: setError }
  )

const productModule = createModule({
  name: 'product',
  initialState: INITIAL_STATE,
  decorators: {
    fetchProducts,
    inspectProduct,
    updateProduct,
    uploadImage,
    setImageFile,
    createProduct,
    filterProducts,
  },
})

export { productModule }
