import { Switch, Route } from 'react-router-dom'

import { ApplicationsManager } from 'components/applications/ApplicationsManager'
import { NotFoundPage } from 'components/common/NotFoundPage'
import { ProtectedRoute } from 'components/common/ProtectedRoute'
import { withNavigation } from 'components/common/withNavigation'
import { FilesManager } from 'components/files/FilesManager'
import { JobsManager } from 'components/jobs/JobsManager'
import { PilotsManager } from 'components/pilots/PilotsManager'
import { ProductsManager } from 'components/products/ProductsManager'
import { StaffManager } from 'components/staff/StaffManager'
import { RESOURCES } from 'constants/resources'
import { usePermissions } from 'lib/permissions'

const resourceToPage = {
  [RESOURCES.PILOT_APPLICATION]: ApplicationsManager,
  [RESOURCES.PILOT_DETAILS]: PilotsManager,
  [RESOURCES.JOB]: JobsManager,
  [RESOURCES.PRODUCT]: ProductsManager,
  [RESOURCES.FILE]: FilesManager,
  [RESOURCES.STAFF]: StaffManager,
}

const lookUpIntitialPage = (permissions) => {
  for (const resource of Object.values(RESOURCES)) {
    if (permissions.can('read', resource)) {
      return resourceToPage[resource]
    }
  }
  return NotFoundPage
}

const Dashboards = withNavigation(() => {
  const permissions = usePermissions()

  return (
    <Switch>
      <ProtectedRoute
        path={['/applications', '/application']}
        component={ApplicationsManager}
        allowed={permissions.can('read', RESOURCES.PILOT_APPLICATION)}
      />

      <ProtectedRoute
        path={['/pilots', '/pilot']}
        component={PilotsManager}
        allowed={permissions.can('read', RESOURCES.PILOT_DETAILS)}
      />

      <ProtectedRoute
        path={['/jobs', '/job']}
        component={JobsManager}
        allowed={permissions.can('read', RESOURCES.JOB)}
      />

      <ProtectedRoute
        path={['/products', '/product']}
        component={ProductsManager}
        allowed={permissions.can('read', RESOURCES.PRODUCT)}
      />

      <ProtectedRoute
        path={['/files', '/file']}
        component={FilesManager}
        allowed={permissions.can('read', RESOURCES.FILE)}
      />

      <ProtectedRoute
        path="/staff"
        component={StaffManager}
        allowed={permissions.can('read', RESOURCES.STAFF)}
      />

      <Route exact path="/" component={lookUpIntitialPage(permissions)} />

      <Route path="/" component={NotFoundPage} />
    </Switch>
  )
})

export { Dashboards }
