import { defaultTo, isNotNil, pick, prop } from '@solta/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi, http } from 'lib/http-client'

const pilotEntity = new schema.Entity('pilots')

const PilotService = {
  async list({ query = '', limit = 1000, next, sortBy }) {
    const config = {
      searchParams: {
        q: query,
        limit,
        sortBy,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await adminApi.get('pilots', config).json()
    const normalized = normalize(items, [pilotEntity])
    const entities = defaultTo({}, prop('pilots', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async read(id) {
    return adminApi.get(`pilots/${id}`).json()
  },

  async update(pilotId, payload) {
    const json = pick(
      [
        'companyName',
        'address',
        'addressLocation',
        'insuranceExpiredAt',
        'insuranceNumber',
        'licenceType',
        'licenceExpiredAt',
        'licenceNumber',
        'serviceCategories',
        'pilotCategoryId',
        'serviceAreas',
        'serviceRadius',
      ],
      payload
    )

    const updatedPilot = await adminApi.patch(`pilots/${pilotId}`, { json }).json()

    return updatedPilot
  },

  async downloadDocument(documentId, pilotId) {
    const { signedUrl } = await adminApi
      .get(`pilots/${pilotId}/documents/${documentId}`)
      .json()

    return http.get(signedUrl).blob()
  },
}

export { PilotService }
