import { isNotNil } from '@solta/ramda-extra'
import { useFormikContext } from 'formik'

import { Button as ButtonBase } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex'))

const EditBtn = styled(ButtonBase)(apply(''))

const CancelBtn = styled(ButtonBase)(apply('bg-transparent shadow-none text-grey-400'))

const SaveBtn = styled(ButtonBase)(apply('ml-4'))

function TabEditing({
  isEditing,
  setIsEditing,
  disabled,
  customHandleCancel,
  ...props
}) {
  const { resetForm, isSubmitting } = useFormikContext()

  const onCancel = () => {
    if (isNotNil(customHandleCancel)) {
      customHandleCancel()
    }
    setIsEditing(false)
    resetForm()
  }

  const onEdit = () => {
    setIsEditing(true)
  }

  return (
    <Container {...props}>
      {(isSubmitting || disabled) && (
        <Spinner size={20} thickness={3} style={apply('mr-3')} />
      )}

      {isEditing ? (
        <>
          <CancelBtn onClick={onCancel}>Cancel</CancelBtn>
          <SaveBtn type="submit" disabled={disabled}>
            Save
          </SaveBtn>
        </>
      ) : (
        <EditBtn onClick={onEdit}>Edit</EditBtn>
      )}
    </Container>
  )
}

export { TabEditing }
