import { adminApi } from 'lib/http-client'

const UploadService = {
  async uploadFile(file, path, json, onError = () => {}, onProgress = () => {}) {
    const {
      signedUrl: presignedUrl,
      filename,
      entity,
    } = await adminApi.post(path, { json }).json()

    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest()

      const performAsynchronously = true
      request.open('PUT', presignedUrl, performAsynchronously)
      request.setRequestHeader('content-type', file.type)

      request.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          onProgress(event.loaded, event.total)
        }
      }

      request.onload = () => {
        const success = request.status >= 200 && request.status < 300

        if (!success) {
          reject(new Error('Failed to upload file. Please try again'))
          onError({ filename, entity })
        } else {
          resolve({ filename, entity })
        }
      }

      request.send(file)
    })
  },
}

export { UploadService }
