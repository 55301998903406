import { Switch, Route } from 'react-router-dom'

import { PilotDetails } from './PilotDetails'
import { PilotList } from './PilotList'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

// eslint-disable-next-line react/display-name
const withPilotList = (Component) => (props) =>
  (
    <>
      <PilotList />
      <Component {...props} />
    </>
  )

function PilotsManager() {
  return (
    <Container>
      <Switch>
        <Route exact path={['/', '/pilots']} component={PilotList} />
        <Route exact path="/pilot/:id" render={withPilotList(PilotDetails)} />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { PilotsManager }
