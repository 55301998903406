import { useMemo } from 'react'

import { ORGANIZATION_ROLES } from 'constants/roles'

export const Columns = (selectedTab, role) =>
  // eslint-disable-next-line complexity
  useMemo(() => {
    let columns = []

    switch (selectedTab) {
      case 'new':
        columns = [
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
          { Header: 'Created At', accessor: 'createdAt' },
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Client', accessor: 'orderedBy' },
          { Header: 'Client Mobile', accessor: 'clientMobile', disableSortBy: true },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Scheduled At', accessor: 'scheduledAt' },
          { Header: 'Notes', accessor: 'description', disableSortBy: true },
        ]
        break
      case 'engaged':
        columns = [
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
          { Header: 'Engaged At', accessor: 'engagedAt' },
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Client', accessor: 'orderedBy' },
          { Header: 'Client Mobile', accessor: 'clientMobile', disableSortBy: true },
          { Header: 'Pilot', accessor: 'pilotName' },
          { Header: 'Pilot Mobile', accessor: 'pilotMobile', disableSortBy: true },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Scheduled At', accessor: 'scheduledAt' },
        ]
        break
      case 'finished':
        columns = [
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
          { Header: 'Created At', accessor: 'createdAt' },
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Client', accessor: 'orderedBy' },
          { Header: 'Pilot', accessor: 'pilotName' },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Started At', accessor: 'startedAt' },
          { Header: 'Finished At', accessor: 'finishedAt' },
        ]
        break
      case 'in_review':
        columns = [
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
          { Header: 'Created At', accessor: 'createdAt' },
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Client', accessor: 'orderedBy' },
          { Header: 'Pilot', accessor: 'pilotName' },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Started At', accessor: 'startedAt' },
          { Header: 'Finished At', accessor: 'finishedAt' },
        ]
        break
      case 'finalised':
        columns = [
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Client', accessor: 'orderedBy' },
          { Header: 'Pilot', accessor: 'pilotName' },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Finished At', accessor: 'finishedAt' },
        ]
        break
      case 'unfulfilled':
        columns = [
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
          { Header: 'Created At', accessor: 'createdAt' },
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Client', accessor: 'orderedBy' },
          { Header: 'Pilot', accessor: 'pilotName' },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Scheduled At', accessor: 'scheduledAt' },
        ]
        break
      case 'overdue':
        columns = [
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
          { Header: 'Created At', accessor: 'createdAt' },
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Client', accessor: 'orderedBy' },
          { Header: 'Client Mobile', accessor: 'clientMobile', disableSortBy: true },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Notes', accessor: 'description', disableSortBy: true },
          { Header: 'Scheduled At', accessor: 'scheduledAt' },
        ]
        break
      case 'cancelled':
        columns = [
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
          { Header: 'Created At', accessor: 'createdAt' },
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Client', accessor: 'orderedBy' },
          { Header: 'Pilot', accessor: 'pilotName' },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Cancelled At', accessor: 'cancelledAt' },
        ]
        break
      default:
        return []
    }

    if (role === ORGANIZATION_ROLES.STAFF) {
      return columns.filter(({ Header }) => !Header.startsWith('Client'))
    }

    return columns
  })
