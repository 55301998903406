import { isNilOrEmpty } from '@solta/ramda-extra'
import { useField } from 'formik'

import { styled, apply } from 'lib/styled'

const Container = styled.div(apply(''))

const Star = styled.i(apply('text-yellow-dark text-2xl'))

const getFullStarNums = (rating) => Math.floor(rating)

function RatingStar({ containerStyle, name, id, ...props }) {
  const [{ value: rating }] = useField({ name, id, ...props })

  if (isNilOrEmpty(rating)) return null

  const fullStarNums = getFullStarNums(rating)
  const hasHalfStar = (rating * 10) % 10 !== 0

  return (
    <Container style={containerStyle}>
      {Array.from(Array(fullStarNums)).map((s, k) => (
        <Star key={k} className="fas fa-star" />
      ))}

      {hasHalfStar && <Star className="fas fa-star-half-alt" />}
    </Container>
  )
}

export { RatingStar }
