import { isInvalidDate } from '@solta/ramda-extra'
import { differenceInMinutes } from 'date-fns'
import { useFormikContext } from 'formik'

import { DateField } from './DateField'

import { apply, styled } from 'lib/styled'

const Container = styled.div(apply('w-full flex items-center'))
const Row = styled.div(apply('flex items-center justify-between'))
const Column = styled.div(apply('flex flex-column justify-evenly w-1/2'))
const Separator = styled.div(apply('my-4 show-box border-grey-light'))
const Value = styled.div(apply('h-2 text-right w-full'))
const Label = styled.div(apply('w-3/4'))

const formatCurrency = (value, ...opts) =>
  new Intl.NumberFormat('au-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...opts,
  }).format(value)

const extractRate = (rate) => ({
  rate: rate ?? 'N/A',
  displayedRate: rate ? `${formatCurrency(rate)} / hr` : 'N/A',
})

const addUnit = (value, unit, plural = `${unit}s`) =>
  `${value} ${value === 1 ? unit : plural}`

// eslint-disable-next-line complexity
function PriceBlock({ isEditing, ...props }) {
  const {
    values: {
      contactInfo: { pilot },
      jobInfo: { startedAt, finishedAt },
    },
  } = useFormikContext()

  let duration = 'N/A'
  let total = 'N/A'
  const { rate, displayedRate } = extractRate(pilot?.pilotCategory?.rate)

  const invalidDates = isInvalidDate(startedAt) || isInvalidDate(finishedAt)

  if (!invalidDates) {
    // We don't care about seconds or milliseconds
    startedAt.setSeconds(0, 0)
    finishedAt.setSeconds(0, 0)

    const diffInMinutes = differenceInMinutes(finishedAt, startedAt)
    if (diffInMinutes >= 0) {
      const hours = Math.floor(diffInMinutes / 60)
      const minutes = diffInMinutes - hours * 60
      duration = `${addUnit(hours, 'hr')} ${addUnit(minutes, 'min')}`

      // If the duration is less than 1 hour, charge for 1 hour
      const hoursToCharge = Math.max(1, diffInMinutes / 60)

      total = rate === 'N/A' ? 'N/A' : formatCurrency(hoursToCharge * rate)
    }
  }

  return (
    <Container {...props}>
      <Column>
        <Row>
          <Value />
          <Value>Retail Price</Value>
        </Row>
        <Row>
          <Label>Started At</Label>
          <DateField
            disabled={!isEditing}
            name="jobInfo.startedAt"
            type="text"
            aria-label="Started At"
            autoOk
            withTimePicker
            rootStyle={apply('w-full', { flex: 'initial' })}
            placeholder={!isEditing ? 'Not Yet Available' : undefined}
          />
        </Row>
        <Row>
          <Label>Finished At</Label>
          <DateField
            disabled={!isEditing}
            name="jobInfo.finishedAt"
            type="text"
            aria-label="Finished At"
            autoOk
            withTimePicker
            rootStyle={apply('w-full', { flex: 'initial' })}
            placeholder={!isEditing ? 'Not Yet Available' : undefined}
          />
        </Row>
        <Separator />
        <Row>
          <Label>Duration</Label>
          <Value>{duration}</Value>
        </Row>
        <Row>
          <Label>Rate</Label>
          <Value>{displayedRate}</Value>
        </Row>
        <Row style={apply('font-semibold text-grey-darker')}>
          <Label>Total</Label>
          <Value>{total}</Value>
        </Row>
      </Column>
    </Container>
  )
}

export { PriceBlock }
