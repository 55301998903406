import { prop } from '@solta/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'

import ArrowLeft from 'assets/arrowLeft.svg'
import { SelectField } from 'components/common/SelectField'
import { TabEditing } from 'components/common/TabEditing'
import { JOB_STATUSES } from 'constants/jobs'
import { styled, apply } from 'lib/styled'

const jobStatusMapper = {
  [JOB_STATUSES.DRAFT]: 'draft',
  [JOB_STATUSES.PUBLISHED]: 'published',
  [JOB_STATUSES.ENGAGED]: 'engaged',
  [JOB_STATUSES.FINISHED]: 'finished',
  [JOB_STATUSES.FINALIZED]: 'finalized',
  [JOB_STATUSES.CANCELLED]: 'cancelled',
  [JOB_STATUSES.IN_REVIEW]: 'in review',
  [JOB_STATUSES.STARTED]: 'unfulfilled',
}

const Container = styled.div(
  apply('px-2 w-full bg-white items-center py-6', {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
  })
)

const GoBackBtn = styled.img(apply('block p-2', { cursor: 'pointer' }))

const Title = styled.span(apply('text-xl text-blue'))

function NavigationTab({
  onGoBackClicked,
  title = 'job_status_in_review',
  isEditing,
  setIsEditing,
  showEditingTab,
}) {
  const { setFieldValue } = useFormContext()

  const statusOptions = Object.entries(JOB_STATUSES).map(([key, value]) => ({
    id: value,
    value,
    label: key,
  }))

  const onSelectChange = (name) => (selectedOption) => {
    setFieldValue(name, prop('value', selectedOption))
  }

  return (
    <Container>
      <GoBackBtn src={ArrowLeft} onClick={onGoBackClicked} />
      {isEditing ? (
        <SelectField
          name="status"
          type="select"
          onChange={onSelectChange('status')}
          isDisabled={!isEditing}
          options={statusOptions}
          placeholder="Select Status"
          isSearchable={false}
          containerProps={{ style: apply('w-16') }}
        />
      ) : (
        <Title>{`${jobStatusMapper[title]?.toUpperCase()} JOB`}</Title>
      )}
      {showEditingTab && (
        <TabEditing
          style={{ marginLeft: 'auto' }}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      )}
    </Container>
  )
}

export { NavigationTab }
