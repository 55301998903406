import { createModule } from '@solta/stateless'

import { ApplicationService } from './service'

import { APPLICATION_STATUSES } from 'constants/applications'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  order: [],
  filterQuery: '',
  filterStatus: APPLICATION_STATUSES.SUBMITTED,
  paging: {
    next: undefined,
  },
})

const fetchApplications = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = true } = {}) => {
      const { filterQuery, paging, filterStatus } = module.getState()
      const next = turnPage ? paging.next : undefined

      const {
        entities,
        order,
        next: newNext,
      } = await ApplicationService.list({
        status: filterStatus,
        query: filterQuery,
        next,
      })

      module.setState({
        entities,
        order,
        paging: {
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const filterApplications = (module) => (_, filter) => {
  module.setState({
    ...filter,
  })

  module.fetchApplications(null, { turnPage: false })
}

const inspectApplication = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      module.setState({
        inspectedEntity: id,
      })

      const application = await ApplicationService.read(id)

      module.setState({
        entities: { [id]: application },
      })
    },
    { errHandler: setError }
  )

const approveApplication = (module, { setError }) =>
  withTryCatch(
    async (applicationId, pilotCategoryId) => {
      const updatedApplication = await ApplicationService.approve(
        applicationId,
        pilotCategoryId
      )

      module.setState({
        entities: {
          [applicationId]: updatedApplication,
        },
      })
    },
    { errHandler: setError }
  )

const rejectApplication = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const updatedApplication = await ApplicationService.reject(id)

      module.setState({
        entities: {
          [id]: updatedApplication,
        },
      })
    },
    { errHandler: setError }
  )

const downloadDocument = (_, { setError }) =>
  withTryCatch(
    async (id, applicationId) => ApplicationService.downloadDocument(id, applicationId),
    { errHandler: setError }
  )

const applicationModule = createModule({
  name: 'application',
  initialState: INITIAL_STATE,
  decorators: {
    fetchApplications,
    filterApplications,
    inspectApplication,
    approveApplication,
    rejectApplication,
    downloadDocument,
  },
})

export { applicationModule }
