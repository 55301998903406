import { map, prop, path, pathEq, pathOr } from '@solta/ramda-extra'
import { select } from '@solta/stateless'

import { productModule } from './module'

const { state$, effectState$ } = productModule

const productEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))
const inspectedEntity$ = select(state$, prop('inspectedEntity'))
const filterQuery$ = select(state$, prop('filterQuery'))

const productList$ = select(order$, productEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const inspectedProduct$ = select(inspectedEntity$, productEntities$, (id, entities) =>
  prop(id, entities)
)

const fetchProductsStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchProducts', 'status'], effectState)
)

const isLoadingProducts$ = select(
  fetchProductsStatus$,
  (status) => status === 'pending'
)

const inspectProductStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectProduct', inspectedEntity, 'status'], effectState)
)

const isInspectingProduct$ = select(
  inspectProductStatus$,
  (status) => status === 'pending'
)

const isUploadingImage$ = select(
  effectState$,
  pathEq(['uploadImage', 'status'], 'pending')
)

const isCreatingProductStatus$ = select(effectState$, (effectState) =>
  pathOr('idle', ['createProduct', 'status'], effectState)
)

const isCreatingProduct$ = select(
  isCreatingProductStatus$,
  (status) => status === 'pending'
)

export {
  inspectedEntity$,
  productList$,
  inspectedProduct$,
  isLoadingProducts$,
  isInspectingProduct$,
  isUploadingImage$,
  filterQuery$,
  productEntities$,
  isCreatingProduct$,
}
