import { connect } from '@solta/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useLayoutEffect } from 'react'

import { ReactComponent as Search } from 'assets/feathers/search.svg'
import { Button } from 'components/common/Button'
import { DateField } from 'components/common/DateField'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { SelectField } from 'components/common/SelectField'
import { styled, apply } from 'lib/styled'
import { jobModule, jobFilters$ } from 'modules/job'
import { projectModule, projectList$ } from 'modules/project'

const Container = styled.div(apply('flex w-full'))
const Details = styled(Form)(apply('flex-1 flex flex-row items-center'))
const FormInput = styled(FormInputBase)(apply('bg-white'))
const SearchButton = styled(Button)(
  apply(
    'rounded-full border-0 mt-6 flex items-center justify-center bg-grey-lighter bg-blue',
    {
      height: '50px',
      width: '50px',
    }
  )
)

const { filterJobs } = jobModule
const { fetchProjects } = projectModule

const Connected = connect(() => ({
  projectList: projectList$,
  jobFilters: jobFilters$,
}))(FilterBar)

function FilterBar({ projectList, jobFilters, selectedTab }) {
  useLayoutEffect(() => {
    fetchProjects(null)
  }, [])

  const onSubmit = (values) => filterJobs(null, values)

  const onFromDateChange = (date) => onSubmit({ from: date })

  const onToDateChange = (date) => onSubmit({ to: date })

  const onProjectChange = (project) => onSubmit({ project: project?.id })

  const projectOptions = projectList.map(({ id, reference }) => ({
    id,
    value: id,
    label: reference,
  }))

  const { from, to, filterQuery, project } = jobFilters

  return (
    <Container>
      <FormProvider
        enableReinitialize
        initialValues={{
          // we pass null when either date filters are `undefined` so that DateField displays the placeholder instead of `today`
          from: from ?? null,
          to: to ?? null,
          filterQuery: filterQuery ?? '',
          project,
        }}
        onSubmit={onSubmit}
      >
        <Details>
          <FormInput
            label="Search by client's / pilot's name or reference"
            name="filterQuery"
            type="text"
            placeholder="e.g. John Smith"
            testId="job-filter-query-input"
            containerProps={{ style: { flexGrow: 4 } }}
          />
          <SelectField
            label="Project"
            name="project"
            type="select"
            placeholder="e.g. ABC123"
            optional
            emptyOption={{ value: undefined }}
            onChange={onProjectChange}
            options={projectOptions}
            containerProps={{
              style: apply('mr-4', { flexGrow: 2 }),
            }}
          />
          <DateField
            name="from"
            type="text"
            label="From"
            onChange={onFromDateChange}
            rootStyle={apply('mr-4')}
            autoOk
            maxDate={selectedTab === 'overdue' ? to : undefined}
          />
          <DateField
            disabled={selectedTab === 'overdue'}
            name="to"
            type="text"
            label="To"
            minDate={from}
            onChange={onToDateChange}
            rootStyle={apply('mr-4')}
            autoOk
          />
          <SearchButton type="submit">
            <Search height={20} width={20} color="white" />
          </SearchButton>
        </Details>
      </FormProvider>
    </Container>
  )
}

export { Connected as FilterBar }
