import { createModule } from '@solta/stateless'

const INITIAL_STATE = Object.freeze({
  isBanned: false,
})

const setUserBanStatus = (module) => async (_, isBanned) =>
  module.setState({ isBanned })

const authModule = createModule({
  name: 'auth',
  initialState: INITIAL_STATE,
  decorators: {
    setUserBanStatus,
  },
})

export { authModule }
