import CONSTANTS from '@solta/constants'
import { values } from '@solta/ramda-extra'
import { Formik as FormProvider, Form } from 'formik'
import { useCallback, useState } from 'react'

import { ReactComponent as Search } from 'assets/feathers/search.svg'
import { ReactComponent as Upload } from 'assets/feathers/upload.svg'
import { Button } from 'components/common/Button'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { DOCUMENT_TYPES } from 'constants/documents'
import { CanCreateFile } from 'lib/permissions'
import { styled, apply } from 'lib/styled'
import { documentModule } from 'modules/document'
import { useFileUpload } from 'utils/hooks'

const Container = styled.div(
  apply('flex bg-blue-lightest py-4 justify-between items-center')
)
const Details = styled(Form)(apply('flex flex-row items-center px-4 pl-4 w-1/2'))
const FormInput = styled(FormInputBase)(apply('bg-white'))
const SearchButton = styled(Button)(
  apply('rounded-full bg-white flex items-center justify-center', {
    height: '50px',
    width: '50px',
  })
)
const UploadButton = styled(Button)(
  apply('bg-white flex items-center justify-center p-4 mx-4')
)

const INITIAL_VALUES = { filterQuery: '' }

function ActionsBar({ setFilterParams, fetchJobDocuments, inspectedJobId }) {
  const [hasError, setHasError] = useState(false)

  const onFileUpload = async ([file]) => {
    await documentModule.uploadJobDocument(null, {
      file,
      documentType: DOCUMENT_TYPES.JOB_ASSET,
    })
  }

  const { getRootProps, getInputProps } = useFileUpload({
    accept: `${values(CONSTANTS.FILE_MIME_TYPES)}`,
    noDrag: true,
    noKeyboard: true,
    onDropRejected: useCallback(() => setHasError(true)),
    onDropAccepted: useCallback((files) => onFileUpload(files)),
  })

  const onSubmit = (values) => {
    setFilterParams(null, values)
    fetchJobDocuments(null, inspectedJobId, { turnPage: false })
  }

  return (
    <Container>
      <FormProvider
        enableReinitialize
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
      >
        <Details>
          <FormInput
            containerProps={{ style: apply('flex-2 rounded-full') }}
            style={apply('rounded-full text-base py-3 px-6 bg-white')}
            name="filterQuery"
            type="text"
            placeholder="Search by file name"
          />
          <SearchButton type="submit">
            <Search height={24} width={24} />
          </SearchButton>
        </Details>
      </FormProvider>

      <ErrorMessage error="invalid file type" visible={hasError} />

      <CanCreateFile>
        <UploadButton {...getRootProps()}>
          <Upload width={15} height={15} style={apply('mr-2')} />
          <input {...getInputProps()} />
          Upload
        </UploadButton>
      </CanCreateFile>
    </Container>
  )
}

export { ActionsBar }
