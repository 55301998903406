import { isNotNil } from '@solta/ramda-extra'
import { Formik as FormProvider, Form } from 'formik'

import { FormInput as FormInputBase } from 'components/common/FormInput'
import { ServiceCategory } from 'components/common/ServiceCategory'
import { LICENCE_TYPE_DISPLAY_NAMES } from 'constants/licenceTypes'
import { styled, apply } from 'lib/styled'
import { formatDate } from 'utils/formatDate'

const Container = styled.section()
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const Details = styled(Form)(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex flex-row my-2', {
    flex: 'auto',
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})
const CategoriesContainer = styled.div(
  apply('flex flex-row', {
    '& > span': apply('mx-2', {
      '&:first-of-type': apply('ml-0'),
      '&:last-of-type': apply('mr-0'),
    }),
  })
)

function SkillsAndAbilities({
  licenceType,
  licenceNumber,
  licenceExpiredAt,
  insuranceNumber,
  insuranceExpiredAt,
  serviceCategories,
}) {
  return (
    <Container>
      <Header>
        <Title>Skills & Abilities</Title>
      </Header>

      <FormProvider
        enableReinitialize
        initialValues={{
          licenceType: LICENCE_TYPE_DISPLAY_NAMES[licenceType],
          licenceNumber,
          licenceExpiredAt: formatDate(licenceExpiredAt),
          insuranceNumber,
          insuranceExpiredAt: isNotNil(insuranceExpiredAt)
            ? formatDate(insuranceExpiredAt)
            : undefined,
        }}
      >
        <Details>
          <Column>
            <Row style={apply('w-1/2')}>
              <FormInput
                readOnly
                name="licenceType"
                type="text"
                label="Licence Type"
                containerProps={{ style: apply('mr-3') }}
              />
            </Row>

            <Row>
              <FormInput
                readOnly
                name="licenceNumber"
                type="text"
                label="Licence Number"
              />

              <FormInput
                readOnly
                name="licenceExpiredAt"
                type="text"
                label="Licence Expiry"
              />
            </Row>

            <Row>
              <FormInput
                readOnly
                name="insuranceNumber"
                type="text"
                label="Insurance Number (optional)"
              />

              <FormInput
                readOnly
                name="insuranceExpiredAt"
                type="text"
                label="Insurance Expiry (optional)"
              />
            </Row>

            <Row style={apply('flex-column')}>
              <Label>Service Categories</Label>
              <CategoriesContainer>
                {serviceCategories.map((category) => (
                  <ServiceCategory
                    key={category}
                    category={category}
                    style={apply('px-3 py-2 pb-3 bg-grey-lighter rounded-lg')}
                  />
                ))}
              </CategoriesContainer>
            </Row>
          </Column>
        </Details>
      </FormProvider>
    </Container>
  )
}

export { SkillsAndAbilities }
