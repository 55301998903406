import { isNil } from '@solta/ramda-extra'

import { ReactComponent as SearchIcon } from 'assets/feathers/search.svg'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply(
    'flex flex-row items-center px-4 py-2 border-1 border-solid border-grey-200 rounded-lg text-base'
  )
)
const Input = styled.input(
  apply('w-full flex-1 m-0 p-0 border-0 bg-transparent font-bold', {
    marginBottom: 3,
    overflowX: 'hidden',
    '&::placeholder': {
      fontWeight: 400,
      opacity: 1,
      color: '#AAA',
    },
  })
)

function SearchInput({
  value,
  onChange,
  placeholder,
  inputProps = {},
  containerProps = {},
}) {
  function handleChange(event) {
    if (isNil(onChange)) {
      return
    }

    onChange(event.target.value)
  }

  return (
    <Container {...containerProps}>
      <SearchIcon style={apply('mr-1')} />

      <Input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        {...inputProps}
      />
    </Container>
  )
}

export { SearchInput }
