import { omit } from '@solta/ramda-extra'

export const roleKey = 'https://nearbysky.com/roles'

export const ROLES = {
  ADMIN: 'organization_admin',
  STAFF: 'organization_staff',
  PILOT: 'pilot',
}

export const ORGANIZATION_ROLES = omit(['PILOT'], ROLES)

export const ORGANIZATION_ROLE_DISPLAY_NAMES = {
  [ORGANIZATION_ROLES.ADMIN]: 'Organization Admin',
  [ORGANIZATION_ROLES.STAFF]: 'Staff',
}
