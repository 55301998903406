import { prop, defaultTo } from '@solta/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi } from 'lib/http-client'

const pilotCategoryEntity = new schema.Entity('pilotCategories')

const PilotCategoryService = {
  async list() {
    const items = await adminApi.get('pilot-categories').json()
    const normalized = normalize(items, [pilotCategoryEntity])
    const entities = defaultTo({}, prop('pilotCategories', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
    }
  },
}

export { PilotCategoryService }
