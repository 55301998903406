const JOB_STATUSES = {
  DRAFT: 'job_status_draft',
  PUBLISHED: 'job_status_published',
  ENGAGED: 'job_status_engaged',
  STARTED: 'job_status_started',
  FINISHED: 'job_status_finished',
  IN_REVIEW: 'job_status_in_review',
  FINALIZED: 'job_status_finalized',
  CANCELLED: 'job_status_cancelled',
}

export { JOB_STATUSES }
