/* eslint-disable react/jsx-key */
import { noop } from '@solta/ramda-extra'

import { styled, apply } from 'lib/styled'

const Tr = styled.tr(apply('text-black no-decoration'), {
  height: '40px',
  fontSize: '14px',
  '&:hover': { backgroundColor: '#f7fcff' },
  '&:last-child': {
    '& td': {
      borderBottom: 0,
    },
  },
  '&:nth-of-type(even)': {
    background: '#fafafa',
    '&:hover': { backgroundColor: '#f7fcff' },
  },
})

const Td = styled.td(apply('px-3 text-left'), {
  margin: '0',
  color: '#18181b',
  '&:last-child': {
    borderRight: 0,
  },
})

const Tbody = styled.tbody(apply('text-center'), {
  maxHeight: '95%',
})

function TableBody({ getTableBodyProps, prepareRow, rows, onClick = noop }) {
  return (
    <>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)

          return (
            <Tr
              key={row.id}
              {...row.getRowProps({
                onClick: () => onClick(row.original.id),
              })}
            >
              {row.cells.map((cell, index) => (
                <Td key={index} {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </>
  )
}

export { TableBody }
