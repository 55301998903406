import { isNil } from '@solta/ramda-extra'
import { connect } from '@solta/stateless'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { General } from './General'
import { PersonalDetails } from './PersonalDetails'
import { ServiceArea } from './ServiceArea'
import { SkillsAndAbilities } from './SkillsAndAbilities'

import { ApproveApplicationModal } from 'components/common/ApproveApplicationModal'
import { DocumentsPanel } from 'components/common/DocumentsPanel'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'
import {
  inspectedApplication$,
  isInspectingApplication$,
  applicationModule,
} from 'modules/application'

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm')
)

const Scrollable = styled.div(
  apply('flex flex-column px-6', {
    overflow: 'auto',
  })
)

const ConnectedApplicationDetails = connect(() => ({
  isInspectingApplication: isInspectingApplication$,
  inspectedApplication: inspectedApplication$,
}))(ApplicationDetails)

function ApplicationDetails({ isInspectingApplication, inspectedApplication }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const { id } = useParams()
  useEffect(() => {
    applicationModule.inspectApplication(id)
  }, [id])

  const onConfirm = (pilotCategoryId) => {
    setIsModalOpen(false)
    applicationModule.approveApplication(id, pilotCategoryId)
  }

  if (isNil(inspectedApplication)) return null

  const {
    status,
    address,
    licenceType,
    licenceNumber,
    licenceExpiredAt,
    insuranceNumber,
    insuranceExpiredAt,
    serviceRadius,
    serviceCategories,
    firstName,
    lastName,
    companyName,
    phoneNumber,
    email,
    avatar,
    documents,
    addressLocation: { coordinates },
  } = inspectedApplication

  return (
    <Container data-test-id="application-details-container">
      <Loader
        loading={isInspectingApplication}
        renderPlaceholder={
          <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
        }
      >
        <General id={id} openModal={openModal} />

        <Scrollable>
          <PersonalDetails
            id={id}
            status={status}
            firstName={firstName}
            lastName={lastName}
            companyName={companyName}
            phoneNumber={phoneNumber}
            email={email}
            avatarSrc={avatar}
          />

          <SkillsAndAbilities
            licenceType={licenceType}
            licenceNumber={licenceNumber}
            licenceExpiredAt={licenceExpiredAt}
            insuranceNumber={insuranceNumber}
            insuranceExpiredAt={insuranceExpiredAt}
            serviceCategories={serviceCategories}
          />

          <ServiceArea
            address={address}
            serviceRadius={serviceRadius}
            coordinates={coordinates}
          />

          <DocumentsPanel
            documents={documents}
            ownerId={id}
            onDownloadClick={applicationModule.downloadDocument}
          />
        </Scrollable>
      </Loader>
      <ApproveApplicationModal
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        onConfirm={onConfirm}
      />
    </Container>
  )
}

export { ConnectedApplicationDetails as ApplicationDetails }
