import { isNil, insert } from '@solta/ramda-extra'
import { useState, useEffect, useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Link as LinkBase, useLocation } from 'react-router-dom'

import { ErrorPage } from '../ErrorPage'

import { NavigationBar } from './NavigationBar'

import { RESOURCES } from 'constants/resources'
import { usePermissions } from 'lib/permissions'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex-1 flex flex-row h-full', { overflow: 'hidden' })
)
const SideBar = styled.div(
  apply(
    'relative z-1 bg-white flex flex-column border-0 border-r-1 border-solid border-grey-light',
    {
      overflow: 'hidden',
      transition: '0.25s width ease, 0.25s box-shadow ease',
    }
  ),
  ({ isOpen }) => ({
    width: isOpen ? 270 : 0,
    boxShadow: isOpen ? apply('shadow').boxShadow : 'none',
  })
)
const Branding = styled.div(
  apply(
    'flex items-center justify-center p-4 border-0 border-b-1 border-solid border-grey-light'
  )
)
const Logo = styled.img(apply('w-8 h-5'))
const Link = styled(LinkBase, { shouldForwardProp: (p) => p !== 'active' })(
  apply(
    'tracking-wide flex flex-row items-center text-sm font-normal p-4 pl-3 text-black border-0 border-b-1 border-solid border-grey-lighter',
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      '&:hover, &:focus': apply('no-decoration'),
    }
  ),
  ({ active }) =>
    active
      ? apply('bg-blue-100 font-semibold')
      : apply('bg-white', {
          '&:hover, &:focus': apply('bg-blue-50', { color: '#115B7E' }),
        })
)

const Icon = styled.i(
  apply('flex flex-row justify-center w-2 mr-2 text-blue-400 text-base')
)
const Content = styled.div(
  apply('flex-1 flex flex-column bg-blue-50', {
    '& > *': {
      overflow: 'hidden',
    },
  })
)

// ATTENTION: paths contain [plural, singular] form and the order is convention only.
const routes = [
  {
    resource: RESOURCES.PILOT_APPLICATION,
    title: 'Applications',
    paths: ['/applications', '/application'],
    icon: 'file-signature',
  },
  {
    resource: RESOURCES.PILOT_DETAILS,
    title: 'Pilots',
    paths: ['/pilots', '/pilot'],
    icon: 'users',
  },
  {
    resource: RESOURCES.JOB,
    title: 'Jobs',
    paths: ['/jobs', '/job'],
    icon: 'list',
  },
  {
    resource: RESOURCES.PRODUCT,
    title: 'Products',
    paths: ['/products', '/product'],
    icon: 'suitcase',
  },
  {
    resource: RESOURCES.FILE,
    title: 'Files',
    paths: ['/files', '/file'],
    icon: 'folder',
  },
  {
    resource: RESOURCES.STAFF,
    title: 'Staff',
    paths: ['/staff'],
    icon: 'users',
  },
]

// this ensures that the rendered routes are ordered by the order specified in constants/resources
const reorderRoutes = (prev, current) => {
  const order = Object.values(RESOURCES)
  const index = order.findIndex((r) => r === current.resource)

  return insert(index, current, prev)
}

function withNavigation(WrappedComponent) {
  return function NavigationComponent(ownProps) {
    const [showSidebar, setShowSidebar] = useState(true)
    const location = useLocation()
    const permissions = usePermissions()
    const visibleRoutes = routes
      .filter((r) => permissions.can('read', r.resource))
      .reduce(reorderRoutes, [])
    const [currentRoute, setCurrentRoute] = useState(visibleRoutes[0])

    useEffect(() => {
      const newCurrentRoute = visibleRoutes.find((route) =>
        route.paths.some((path) => location.pathname.includes(path))
      )

      if (isNil(newCurrentRoute)) {
        return
      }

      setCurrentRoute(newCurrentRoute)
    }, [location])

    const onSidebarToggle = useCallback(() => {
      setShowSidebar(!showSidebar)
    })

    return (
      <Container>
        <SideBar isOpen={showSidebar}>
          <Branding>
            <Logo src="/ghd-logo.jpg" />
          </Branding>

          {visibleRoutes.map(({ resource, paths, title, icon }) => (
            <Link
              key={resource}
              to={paths[0]}
              active={resource === currentRoute?.resource}
            >
              <Icon className={`fas fa-${icon}`} />
              {title}
            </Link>
          ))}
        </SideBar>

        <Content>
          <NavigationBar
            title={currentRoute.title}
            showSidebar={showSidebar}
            onSidebarToggle={onSidebarToggle}
          />
          <ErrorBoundary FallbackComponent={ErrorPage} resetKeys={[currentRoute]}>
            <WrappedComponent {...ownProps} />
          </ErrorBoundary>
        </Content>
      </Container>
    )
  }
}

export { withNavigation }
