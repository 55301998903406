import { createTheme, ThemeProvider } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import { isNotNilOrEmpty, isNotNil, noop } from '@solta/ramda-extra'
import { useField } from 'formik'

import { ErrorMessage } from 'components/common/ErrorMessage'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('flex flex-1 flex-column'))

const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const Input = styled(TextField, { shouldForwardProp: (p) => p !== 'inputStyles' })(
  apply(
    'bg-grey-100 text-lg text-black border-0 border-b-2 border-grey rounded-md px-3 py-2'
  ),
  ({ disabled }) =>
    disabled ? apply('bg-gray-400 border-gray-700 text-gray-600') : {},
  ({ readOnly }) =>
    readOnly
      ? apply('bg-gray-400 border-gray-700', {
          '& .MuiInputBase-input': apply('text-gray-800'),
        })
      : {},
  ({ inputStyles }) =>
    apply('', {
      '& .MuiInputBase-root': apply('', {
        lineHeight: 'inherit',
        fontFamily: 'unset',
      }),
      '& .MuiInputBase-input': apply('text-lg py-0', {
        height: 'inherit',
        ...inputStyles,
      }),
      '& .MuiIconButton-edgeEnd': {
        padding: '6px',
        marginRight: '-8px',
      },
    }),
  {
    '& .MuiFormHelperText-root': apply('hidden'),
    '& .MuiInputBase-root::before': apply('hidden'),
    '& .MuiInputBase-root::after': apply('hidden'),
  }
)

const datePickerTheme = createTheme({
  overrides: {
    MuiPickersDay: {
      day: {
        '&:focus': apply('text-white'),
      },
      daySelected: {
        ...apply('text-white'),
        '&:hover': {
          backgroundColor: '#9E2AA4',
        },
      },
    },
  },
})

// eslint-disable-next-line complexity
function DateField({
  disabled,
  readOnly,
  name,
  id,
  label,
  labelPosition,
  containerProps,
  pickerProps,
  minDate,
  rootStyle,
  inputStyles,
  onChange = noop,
  testId,
  withTimePicker = false,
  placeholder,
  ...props
}) {
  const [{ value }, { touched, error }, { setValue: setFieldValue, setTouched }] =
    useField({
      name,
    })

  function handleChange(dateObject, dateString) {
    setTouched(true)
    setFieldValue(dateObject)
    onChange(dateObject, dateString)
  }

  const Picker = withTimePicker ? DateTimePicker : DatePicker

  const overridePlaceholder = (inputProps = {}) => {
    if (isNotNil(placeholder)) {
      return { ...inputProps, placeholder }
    }

    return inputProps
  }

  return (
    <Root style={rootStyle}>
      <ThemeProvider theme={datePickerTheme}>
        <Label
          isVisible={isNotNilOrEmpty(label)}
          htmlFor={id ?? name}
          labelPosition={labelPosition}
        >
          {label}
        </Label>
        <Picker
          minDate={minDate}
          value={value}
          disabled={disabled || readOnly}
          inputFormat={withTimePicker ? 'dd/MM/yyyy p' : 'dd/MM/yyyy'}
          onChange={handleChange}
          renderInput={({ inputProps, ...params }) => (
            <Input
              {...params}
              inputStyles={inputStyles}
              onBlur={() => setTouched(true)}
              data-test-id={testId}
              inputProps={overridePlaceholder(inputProps)}
              id={id ?? name}
            />
          )}
          {...props}
        />
      </ThemeProvider>
      <ErrorMessage visible={touched} error={error} />
    </Root>
  )
}

export { DateField }
