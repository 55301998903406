import { Switch, Route } from 'react-router-dom'

import { FileTable } from './FileTable'
import { JobFileTable } from './JobFileTable'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

function FilesManager() {
  return (
    <Container>
      <Switch>
        <Route exact path={['/', '/files']} component={JobFileTable} />
        <Route exact path="/file/:id" component={FileTable} />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { FilesManager }
