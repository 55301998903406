import { RatingStar } from '../RatingStars'

import { TextAreaField } from 'components/common/TextAreaField'
import { apply, styled } from 'lib/styled'

const Container = styled.div(apply('mb-8'))
const Details = styled.div(apply('flex-column items-start pl-0'))
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const Row = styled.div(
  apply('flex justify-between w-32', {
    '@media screen and (max-width: 1600px)': apply('mb-4 justify-center'),
  })
)
const Column = styled.div(apply('flex flex-column flex-1 justify-between my-2'))

const Label = styled.p(
  apply('mb-2 uppercase tracking-wide text-md text-grey-dark mr-8'),
  {
    whiteSpace: 'nowrap',
  }
)

function RatingAndFeedback(props) {
  return (
    <Container {...props}>
      <Header>
        <Title>Rating &amp; Feedback</Title>
      </Header>

      <Details>
        <Row>
          <Column>
            <Label>Communication</Label>
            <Label>Media &amp; Photo Quality</Label>
            <Label>Overall Rating</Label>
          </Column>
          <Column>
            <RatingStar
              containerStyle={{ textAlign: 'left' }}
              name="ratingAndFeedback.communication"
            />
            <RatingStar
              containerStyle={{ textAlign: 'left' }}
              name="ratingAndFeedback.mediaAndPhotoQuality"
            />
            <RatingStar
              containerStyle={{ textAlign: 'left' }}
              name="ratingAndFeedback.overall"
            />
          </Column>
        </Row>

        <TextAreaField
          readOnly
          disabled
          name="ratingAndFeedback.feedback"
          label="Feedback (optional)"
          containerStyle={apply('w-full')}
          minRows={6}
        />
      </Details>
    </Container>
  )
}

export { RatingAndFeedback }
