import { connect } from '@solta/stateless'
import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { FilterInput } from './FilterInput'
import { PilotListItem } from './PilotListItem'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'
import {
  pilotModule,
  pilotList$,
  inspectedEntity$,
  isLoadingPilots$,
} from 'modules/pilot'

const Container = styled.div(
  apply('flex flex-column w-1/4 min-w-10 max-w-24 mr-6 bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const InputContainer = styled.div(
  apply('p-4 border-0 border-b-1 border-solid border-grey-lighter')
)

const List = styled.ol(
  apply('p-0 m-0', {
    overflow: 'auto',
    listStyleType: 'none',
  })
)

const ConnectedPilotList = connect(() => ({
  pilotList: pilotList$,
  isLoadingPilots: isLoadingPilots$,
  inspectedEntity: inspectedEntity$,
}))(PilotList)

const { fetchPilots } = pilotModule

function PilotList({ isLoadingPilots, pilotList, inspectedEntity, ...props }) {
  useLayoutEffect(() => {
    fetchPilots(null)
  }, [])

  const history = useHistory()

  return (
    <Container {...props}>
      <InputContainer>
        <FilterInput />
      </InputContainer>

      <Loader
        loading={isLoadingPilots}
        renderPlaceholder={<Spinner size={32} thickness={6} style={apply('mt-4')} />}
      >
        <List>
          {pilotList.map(({ id, activatedAt, isBanned, isDeleted, userDetails }) => {
            const { fullName, phoneNumber, avatar } = userDetails
            const isInspected = id === inspectedEntity

            return (
              <PilotListItem
                key={id}
                isInspected={isInspected}
                isBanned={isBanned}
                isDeleted={isDeleted}
                fullName={fullName}
                phoneNumber={phoneNumber}
                activatedAt={activatedAt}
                avatarSrc={avatar}
                onClick={() => history.push(`/pilot/${id}`)}
              />
            )
          })}
        </List>
      </Loader>
    </Container>
  )
}

export { ConnectedPilotList as PilotList }
