import { pickThenRename, noop } from '@solta/ramda-extra'
import { connect } from '@solta/stateless'
import { useFormikContext as useFormContext } from 'formik'
import { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'

import { DateField } from 'components/common/DateField'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { LocationField } from 'components/common/LocationField'
import { SelectField } from 'components/common/SelectField'
import { ComplianceSection, AreaOfInterest } from 'components/jobs/common'
import { DOCUMENT_TYPES } from 'constants/documents'
import { styled, apply } from 'lib/styled'
import { documentModule } from 'modules/document'
import { productModule, productList$ } from 'modules/product'
import { projectModule, projectList$ } from 'modules/project'

const Container = styled.section()

const Column = styled.div(apply('flex-1 flex flex-column'))
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

const Connected = connect(() => ({
  productList: productList$,
  projectList: projectList$,
}))(JobInfo)

function JobInfo({ productList, projectList, minScheduledAtDate }) {
  const {
    setFieldValue,
    values: {
      jobInfo: { address, radius },
    },
  } = useFormContext()

  const coordinates = address?.geometry?.coordinates

  const productOptions = productList
    .filter((product) => product.isActive)
    .map(({ id, name: label }) => ({
      id,
      value: label,
      label,
    }))
  const projectOptions = projectList.map(({ id, reference }) => ({
    id,
    value: reference,
    label: reference,
  }))

  const { id } = useParams()
  useLayoutEffect(() => {
    productModule.fetchProducts(null)
    projectModule.fetchProjects(null)
    documentModule.filterJobDocuments(id, {
      documentTypes: [
        DOCUMENT_TYPES.JOB_TERMS,
        DOCUMENT_TYPES.JOB_JSEA,
        DOCUMENT_TYPES.JOB_CASA,
      ],
    })

    // must reset so that if a non-draft job's inspected next all documents are fetched by default. This is an issue with the filters being a global state
    return () => documentModule.reset()
  }, [])

  return (
    <Container>
      <Header>
        <Title>Job Info</Title>
      </Header>
      <Column>
        <Row>
          <SelectField
            name="jobInfo.product.name"
            label="Product Name"
            onChange={(selectedOption) => {
              setFieldValue(
                'jobInfo.product',
                pickThenRename(['id', 'value'], { value: 'name' })(selectedOption)
              )
            }}
            options={productOptions}
            placeholder="Search and Select Product"
            onInputChange={productModule.filterProducts}
            containerProps={{ style: apply('mr-3') }}
            testId="job-product-name"
          />
          <DateField
            name="jobInfo.scheduledAt"
            label="Scheduled at"
            autoOk
            disablePast
            inputDisabled
            minDate={minScheduledAtDate}
            onError={noop}
            testId="job-scheduled-at"
          />
        </Row>
        <Row style={apply('w-1/2')}>
          <SelectField
            name="jobInfo.project.reference"
            label="Project ID (optional)"
            optional
            creatable
            emptyOption={{ id: undefined, value: undefined }}
            onChange={({ id, value: reference }, { action }) => {
              if (action === 'create-option') {
                projectModule.createProject(null, reference)
              }

              setFieldValue('jobInfo.project', { id, reference })
            }}
            options={projectOptions}
            testId="job-project"
          />
        </Row>
        <Row>
          <LocationField
            name="jobInfo.address"
            label="Location"
            testId="job-location"
            containerProps={{ style: apply('mr-3', { flexGrow: 6 }) }}
          />
          <FormInput
            name="jobInfo.radius"
            label="Radius (meters)"
            testId="job-radius"
          />
        </Row>

        <AreaOfInterest coordinates={coordinates} radius={radius} />

        <Row>
          <FormInput
            name="jobInfo.description"
            as="textarea"
            label="Description"
            testId="job-description"
          />
        </Row>
        <Row>
          <FormInput
            name="jobInfo.instructions"
            as="textarea"
            label="Instructions (optional)"
            testId="job-instructions"
          />
        </Row>
        <ComplianceSection />
      </Column>
    </Container>
  )
}

export { Connected as JobInfo }
