/* eslint-disable camelcase */
import createAuth0Client from '@auth0/auth0-spa-js'
import { isNil } from '@solta/ramda-extra'

let instance

async function getIdentityClientInstance(options) {
  if (isNil(instance)) {
    await createIdentityClient(options)
  }

  return instance
}

async function createIdentityClient({ domain, clientId, redirectUrl }) {
  instance = await createAuth0Client({
    domain,
    client_id: clientId,
    redirect_uri: redirectUrl,
  })
}

async function getToken() {
  const identityClient = await getIdentityClientInstance()
  return identityClient.getTokenSilently()
}

export { getIdentityClientInstance, getToken }
