import { isNil } from '@solta/ramda-extra'

function safelyParseDate(date) {
  if (isNil(date)) {
    return 'Unspecified'
  }

  return new Date(date)
}

export { safelyParseDate }
