import { map, prop, path, pathOr } from '@solta/ramda-extra'
import { select } from '@solta/stateless'

import { userModule } from './module'

const { state$, effectState$ } = userModule

const userEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))
const inspectedEntity$ = select(state$, prop('inspectedEntity'))
const filterQuery$ = select(state$, prop('filterQuery'))

const userList$ = select(order$, userEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const fetchUsersStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchUsers', 'status'], effectState)
)

const isLoadingUsers$ = select(fetchUsersStatus$, (status) => status === 'pending')

export { inspectedEntity$, userList$, isLoadingUsers$, filterQuery$ }
