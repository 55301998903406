import CONSTANTS from '@solta/constants'
import { isNilOrEmpty, noop, values } from '@solta/ramda-extra'
import { connect } from '@solta/stateless'
import FS from 'filesize'
import { useField } from 'formik'
import { useState, useCallback } from 'react'

import { ErrorMessage } from 'components/common/ErrorMessage'
import { Spinner } from 'components/common/Spinner'
import { SendUploadReminder } from 'components/jobs/common/SendUploadReminder'
import { JOB_STATUSES } from 'constants/jobs'
import { CanReadFile, CanUpdateFile } from 'lib/permissions'
import { styled, apply } from 'lib/styled'
import { sendUploadReminderStatus$, inspectedJob$ } from 'modules/job'
import { useFileUpload, createErrorMessage } from 'utils/hooks'

const Panel = styled.div(apply('relative flex flex-column'))

const TopBar = styled.div(apply('flex items-center justify-between mb-2'))

const Label = styled.label(
  apply('uppercase tracking-wide text-xs text-grey-dark flex-1')
)

const EntityText = styled.span(apply('font-semibold'))

const btnStyle = apply('text-xl mr-2 text-grey-darker text-red-light', {
  cursor: 'pointer',
  transition: 'opacity 150ms ease-in-out',
  '&:hover': {
    opacity: 0.4,
  },
})

const DeleteBtn = styled.i(btnStyle, ({ disabled }) => ({
  pointerEvents: disabled && 'none',
  color: disabled ? 'gray !important' : '',
}))

const DownloadBtn = styled.i(btnStyle, ({ disabled }) =>
  apply('ml-2', {
    pointerEvents: disabled && 'none',
    color: disabled ? 'gray !important' : '',
  })
)
const UploadBtn = styled.i(btnStyle, ({ disabled }) =>
  apply('ml-2', {
    pointerEvents: disabled && 'none',
    color: disabled ? 'gray !important' : '',
  })
)

const DataContainer = styled.div(
  apply('relative h-16 border-solid border-0 rounded-md bg-grey-100 py-2', {
    overflowY: 'auto',
  })
)

const Text = styled.span(apply('text-base text-blue-light py-1 px-4'))

const Item = styled.div(
  apply('flex items-center justify-between', {
    cursor: 'pointer',
    '&:hover': apply('bg-grey-lightest'),
  }),
  ({ selected }) =>
    selected &&
    apply('bg-white', {
      '&:hover': apply('bg-white'),
    })
)

const SpinnerContainer = styled.div(apply('absolute'), {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const Overlay = styled.div(
  apply('absolute bg-white', {
    opacity: 0.5,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  })
)

const variantMapper = {
  download: {
    icon: 'fas fa-cloud-download-alt',
    style: apply('text-teal'),
    allowUpload: false,
  },
  upload: {
    icon: 'fas fa-cloud-upload-alt',
    style: apply('text-blue-light'),
    allowUpload: true,
  },
}

const ConnectedFilePanel = connect(() => ({
  sendUploadReminderStatus: sendUploadReminderStatus$,
  inspectedJob: inspectedJob$,
}))(FilePanel)

// eslint-disable-next-line complexity
function FilePanel({
  entity = 'GHD',
  type = 'download',
  name,
  id,
  containerStyle,
  onDownload = noop,
  onUpload = noop,
  onDelete = noop,
  disabled,
  onRejected = noop,
  acceptFileType = `${values(CONSTANTS.FILE_MIME_TYPES)}`,
  isLoading = false,
  itemPrefix,
  sendUploadReminderStatus,
  inspectedJob,
  ...props
}) {
  const variant = variantMapper[type]

  const [{ value: documents }, { error }] = useField({
    name,
    id,
    ...props,
  })

  const { getRootProps, getInputProps } = useFileUpload({
    accept: acceptFileType,
    noDrag: true,
    noKeyboard: true,
    onDropRejected: useCallback((fileRejections) =>
      onRejected(name, createErrorMessage(fileRejections))
    ),
    disabled,
    onDropAccepted: useCallback((files) => onUpload(files, name)),
  })

  const [selectedFile, setSelectedFile] = useState({
    id: '',
    fileName: '',
  })

  const disableDownloadBtn = isNilOrEmpty(selectedFile.id) || isLoading
  const disableDeleteBtn = isNilOrEmpty(selectedFile.id) || isLoading

  return (
    <Panel style={containerStyle}>
      <TopBar>
        <div style={apply('flex')}>
          <Label>
            Files uploaded by <EntityText>{entity}</EntityText>
          </Label>
          {entity === 'Pilot' && inspectedJob?.status === JOB_STATUSES.FINISHED && (
            <SendUploadReminder
              style={apply('ml-1')}
              status={sendUploadReminderStatus}
              jobId={inspectedJob.id}
            />
          )}
        </div>

        <div>
          <DeleteBtn
            className="fas fa-trash-alt"
            onClick={() => onDelete(selectedFile)}
            disabled={disableDeleteBtn}
          />
          {variant.allowUpload && (
            <CanUpdateFile>
              <UploadBtn
                className={variantMapper.upload.icon}
                style={variantMapper.upload.style}
                disabled={isLoading}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
              </UploadBtn>
            </CanUpdateFile>
          )}
          <CanReadFile>
            <DownloadBtn
              className={variantMapper.download.icon}
              style={variantMapper.download.style}
              onClick={() => onDownload(selectedFile, name)}
              disabled={disableDownloadBtn}
            />
          </CanReadFile>
        </div>
      </TopBar>

      <DataContainer isLoading={isLoading}>
        {documents.map(({ id, fileName, fileSize = 0 }) => (
          <Item
            key={id}
            selected={selectedFile.id === id}
            onClick={() => setSelectedFile({ id, fileName })}
          >
            <Text>
              {itemPrefix} - {fileName}
            </Text>
            <Text>{FS(fileSize)}</Text>
          </Item>
        ))}
        <SpinnerContainer hidden={!isLoading}>
          <Spinner size={64} thickness={7} />
        </SpinnerContainer>
        <Overlay hidden={!isLoading} />
      </DataContainer>
      <ErrorMessage error={error} visible={true} style={apply('text-sm')} />
    </Panel>
  )
}

export { ConnectedFilePanel as FilePanel }
