import ky from 'ky'

import { CONFIG } from 'config'
import { getToken } from 'lib/solta-id'

const http = ky.create()

const baseApi = http.extend({
  prefixUrl: `${CONFIG.API.URL}`,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = await getToken()

        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const adminApi = baseApi.extend({
  prefixUrl: `${CONFIG.API.URL}/admin`,
})

export { adminApi, baseApi, http }
