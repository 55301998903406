/* eslint-disable camelcase */
import { isNotNil } from '@solta/ramda-extra'
import { format } from 'date-fns'

import { Avatar } from 'components/common/Avatar'
import { ORGANIZATION_ROLES, ORGANIZATION_ROLE_DISPLAY_NAMES } from 'constants/roles'
import { styled, apply } from 'lib/styled'

const Container = styled.li(
  apply(
    'relative flex flex-row items-center px-4 py-2 border-0 border-b-1 border-solid border-grey-lighter',
    {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    }
  ),
  ({ isInspected, isDeleted }) =>
    apply(
      isInspected ? 'bg-blue-lightest' : 'bg-white',
      isDeleted
        ? {
            opacity: 0.2,
            pointerEvents: 'none',
          }
        : {}
    )
)
const Details = styled.div(apply('flex-1 flex flex-column mb-1'))

const FullName = styled.span(apply('font-semibold text-lg text-near-black'))
const Text = styled.span(
  apply('font-light text-sm text-grey'),
  ({ isBanned }) => isBanned && apply('text-red-lighter')
)
const RoleText = styled.span(({ isOrgAdmin }) =>
  apply('font-light text-sm', isOrgAdmin ? { color: 'limegreen' } : apply('text-grey'))
)

const JoinedAt = styled.span(
  apply('font-light text-sm italic text-grey', {
    '& > b': apply('font-semibold'),
  })
)

const PendingActivationSpan = styled.span(apply('text-red-lighter'))

const getStatus = (isBanned, activatedAt) => {
  let status = 'Inactivated'
  if (isBanned) {
    status = 'Suspended'
  }
  if (!isBanned && activatedAt) {
    status = 'Active'
  }

  return status
}

function StaffListItem({
  isInspected,
  isBanned,
  isDeleted,
  fullName,
  role,
  activatedAt,
  avatarSrc,
  onClick,
  onDelete,
  ...props
}) {
  return (
    <Container
      isDeleted={isDeleted}
      isInspected={isInspected}
      onClick={onClick}
      {...props}
    >
      <Avatar src={avatarSrc} style={apply('mr-4')} />

      <Details>
        <FullName>{fullName}</FullName>
        <RoleText
          isOrgAdmin={role === ORGANIZATION_ROLES.ADMIN}
        >{`(${ORGANIZATION_ROLE_DISPLAY_NAMES[role]})`}</RoleText>
        <Text isBanned={isBanned}>{`Status: ${getStatus(isBanned, activatedAt)}`}</Text>
        <JoinedAt>
          {isNotNil(activatedAt) ? (
            <>
              Since <b>{format(new Date(activatedAt), 'MMM yyyy')}</b>
            </>
          ) : (
            <PendingActivationSpan>Pending user activation</PendingActivationSpan>
          )}
        </JoinedAt>
      </Details>
    </Container>
  )
}

export { StaffListItem }
