import { prop, propOr } from '@solta/ramda-extra'
import FS from 'filesize'
import moment from 'moment'
import { useMemo } from 'react'

import { ActionsCell } from './ActionsCell'

export const Rows = (jobDocumentList, actionCellProps) =>
  useMemo(() =>
    jobDocumentList.map((document) => ({
      id: prop('id', document),
      name: prop('fileName', document),
      size: FS(propOr(0, 'fileSize', document)),
      modifiedAt: moment(prop('updatedAt', document)).format('DD/MM/YY hh:mm A'),
      actions: (
        <ActionsCell
          rowData={{
            name: prop('fileName', document),
            id: prop('id', document),
            documentType: prop('documentType', document),
          }}
          {...actionCellProps}
        />
      ),
    }))
  )
