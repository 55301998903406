import { isNotNil } from '@solta/ramda-extra'
import { format } from 'date-fns'

import { Avatar } from 'components/common/Avatar'
import { styled, apply } from 'lib/styled'

const Container = styled.li(
  apply(
    'relative flex flex-row items-center px-4 py-2 border-0 border-b-1 border-solid border-grey-lighter',
    {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    }
  ),
  ({ isInspected, isDeleted }) =>
    apply(
      isInspected ? 'bg-blue-lightest' : 'bg-white',
      isDeleted
        ? {
            opacity: 0.2,
            pointerEvents: 'none',
          }
        : {}
    )
)
const Details = styled.div(apply('flex-1 flex flex-column mb-1'))

const FullName = styled.span(apply('font-semibold text-lg text-near-black'))
const PhoneNumber = styled.span(
  apply('font-light text-sm', {
    color: '#C8C8C8',
  })
)
const JoinedAt = styled.span(
  apply('font-light text-sm italic', {
    color: '#C8C8C8',
    '& > b': apply('font-semibold'),
  })
)

const PendingActivationSpan = styled.span(apply('text-red-lighter'))

const BannedPill = styled.span(
  apply('uppercase font-medium tracking-wide px-2 rounded-lg bg-red-lighter', {
    height: 16,
    fontSize: 10,
    paddingBottom: 2,
  })
)

function PilotListItem({
  isInspected,
  isBanned,
  isDeleted,
  fullName,
  phoneNumber,
  activatedAt,
  avatarSrc,
  onClick,
  onDelete,
  ...props
}) {
  return (
    <Container
      isDeleted={isDeleted}
      isInspected={isInspected}
      onClick={onClick}
      {...props}
    >
      <Avatar src={avatarSrc} style={apply('mr-4')} />

      <Details>
        <FullName>{fullName}</FullName>
        <PhoneNumber>{phoneNumber}</PhoneNumber>
        <JoinedAt>
          {isNotNil(activatedAt) ? (
            <>
              Since <b>{format(new Date(activatedAt), 'MMM yyyy')}</b>
            </>
          ) : (
            <PendingActivationSpan>Pending pilot activation</PendingActivationSpan>
          )}
        </JoinedAt>
      </Details>

      {isBanned && <BannedPill>Deactivated</BannedPill>}
    </Container>
  )
}

export { PilotListItem }
