import { useMemo } from 'react'

export const Columns = () =>
  useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      disableSortBy: true,
      Cell: function Cell({ value }) {
        return <div>{value}</div>
      },
    },
    { Header: 'Size', accessor: 'size' },
    { Header: 'Last Modified', accessor: 'modifiedAt' },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      id: 'jobDocumentActions',
    },
  ])
